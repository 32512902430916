import { defineMessages } from "react-intl";

export const messages = defineMessages({
  freeProductsHeader: {
    id: "OrWyrh",
    defaultMessage: "Free Bundle Products",
    description: "section header",
  },
  freeProductsButton: {
    id: "U8eeLW",
    defaultMessage: "Assign products",
    description: "button",
  },
  freeProductsTableProductHeader: {
    id: "6cMkfT",
    defaultMessage: "Product Name",
    description: "table head",
  },
  freeProductsTableTypeHeader: {
    id: "bPFp8B",
    defaultMessage: "Product Type",
    description: "product type",
  },
  freeProductsTableAvailabilityHeader: {
    id: "Oe62bR",
    defaultMessage: "Availability",
    description: "product availability",
  },
  freeProductsNotFound: {
    id: "EPDXR/",
    defaultMessage: "No products assigned",
    description: "no products",
  },
});
