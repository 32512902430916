import ActionDialog from "@dashboard/components/ActionDialog";
import { Button } from "@dashboard/components/Button";
import CardTitle from "@dashboard/components/CardTitle";
import ControlledCheckbox from "@dashboard/components/ControlledCheckbox";
import FormSpacer from "@dashboard/components/FormSpacer";
import Grid from "@dashboard/components/Grid";
import Hr from "@dashboard/components/Hr";
import ImageUpload from "@dashboard/components/ImageUpload";
import MediaTile from "@dashboard/components/MediaTile";
import PriceField from "@dashboard/components/PriceField";
import Savebar from "@dashboard/components/Savebar";
import SketchColorPicker from "@dashboard/components/SketchColorPicker";
import useNavigator from "@dashboard/hooks/useNavigator";
import useNotifier from "@dashboard/hooks/useNotifier";
import { commonMessages } from "@dashboard/intl";
import { Card, CardContent, makeStyles, TextField } from "@material-ui/core";
import { ConfirmButtonTransitionState } from "@saleor/macaw-ui";
import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useIntl } from "react-intl";

const useStyles = makeStyles(
  () => ({
    error: {
      color: "red",
    },
    errorsList: {
      marginBottom: "-15px",
    },
    uploadWrapper: {
      width: "50%",
    },
    colors: {
      display: "flex",
    },
    firstColor: {
      marginRight: "50px",
    },
    fileField: {
      display: "none",
    },
    input: {
      display: "none",
    },
    info: {
      opacity: "0.8",
    },
    divider: {
      marginTop: 25,
      marginBottom: 25,
    },
    imageWrapper: {},
    uploadArea: {
      cursor: "pointer",
      border: "1px dashed #ccc",
      height: "48px",
      borderRadius: "4px",
      marginRight: "30px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
    },
  }),
  { name: "Form" },
);

const getImageFromFile = (data: File): Promise<string> =>
  new Promise(resolve => {
    const reader = new FileReader();
    reader.onloadend = function (e) {
      resolve(e.target.result.toString());
    };
    reader.readAsDataURL(data);
  });

export interface FormData {
  company: string;
  descriptionDe: string;
  descriptionEn: string;
  descriptionFr: string;
  descriptionIt: string;
  descriptionEs: string;
  image?: string | File;
  gradientEnd: string;
  gradientStart: string;
  price: number;
  shopLinkDe: string;
  shopLinkEn: string;
  shopLinkFr: string;
  shopLinkIt: string;
  shopLinkEs: string;
  isActive: boolean;
  titleDe: string;
  titleEn: string;
  titleFr: string;
  titleIt: string;
  titleEs: string;
  expirationDate: string;
  expirationTime: string;
  releaseTime: string;
  releaseDate: string;
  periodEndDate: string;
  periodEndTime: string;
  periodStartDate: string;
  periodStartTime: string;
  voucherCsvFile?: File;
  voucher: string;
}

export interface FormRef {
  resetVoucherCsvFile: () => void;
}

interface FormProps {
  ref?: ForwardedRef<FormRef>;
  data: FormData;
  language: string;
  cancelUrl: string;
  confirmBtnText: string;
  onSubmit: (data: FormData) => void;
  onRemove?: () => void;
  progressState: ConfirmButtonTransitionState;
  serverErrors: string[];
}

export const FlowmilesOfferForm: React.FC<FormProps> = forwardRef(
  (
    {
      data,
      language,
      cancelUrl,
      confirmBtnText,
      onSubmit,
      onRemove,
      progressState,
      serverErrors,
    },
    ref,
  ) => {
    const navigate = useNavigator();
    const notify = useNotifier();
    const intl = useIntl();
    const styles = useStyles();

    const imagesUpload = useRef<HTMLInputElement>(null);
    const fileUpload = useRef<HTMLInputElement | null>(null);

    const [values, setValues] = useState<FormData>(data);
    const [isDirty, setIsDirty] = useState(false);
    const [errors, setErrors] = useState<Record<string, string>>({});
    const [imageUrl, setImageUrl] = useState("");

    const [showRemoveModalConfirmation, changeRemoveModalConfirmationState] =
      useState(false);

    useEffect(() => {
      setImageUrl(data.image as string);
    }, [data.image]);

    useEffect(() => {
      if ((values.image as File).type) {
        getImageFromFile(values.image as File).then(setImageUrl);
      } else {
        setImageUrl(values.image as string);
      }
    }, [values.image]);

    useEffect(() => {
      const error: Record<string, string> = {};

      if (!values.titleDe) {
        error.titleDe = "Title field is required";
      }

      if (!values.descriptionDe) {
        error.descriptionDe = "Description field is required";
      }

      if (!values.company) {
        error.company = "Company field is required";
      }

      if (!(values.expirationDate || values.expirationTime)) {
        error.expirationDate = "Expiration field is required";
      }

      if (!(values.releaseDate || values.releaseTime)) {
        error.releaseDate = "Release field is required";
      }

      if (!(values.periodStartDate || values.periodStartTime)) {
        error.periodStartDate = "Period start field is required";
      }

      if (!(values.periodEndDate || values.periodEndTime)) {
        error.periodEndDate = "Period end field is required";
      }

      setErrors(error);
    }, [values, isDirty]);

    const onFieldChange = (name: string, value: string | File) => {
      setValues({ ...values, ...{ [name]: value } });
    };

    const getValueByLang = (name: string) => values[getObjKeyByLang(name)];
    const getObjKeyByLang = (name: string) =>
      `${name}${language.charAt(0).toUpperCase() + language.slice(1)}`;

    const onConfirm = () => {
      if (errors && Object.values(errors).length > 0) {
        setIsDirty(true);
        notify({
          status: "error",
          title: "Error",
          text: "Please, fill all required fields",
        });
      } else {
        onSubmit(values);
      }
    };

    const handleImageUpload = async (files: FileList) => {
      const file = files[0];

      if (!(file.type === "image/png" || file.type === "image/jpeg")) {
        notify({
          status: "error",
          title: "Error",
          text: "Please, select correct image file .jpg or .png",
        });
        return;
      }

      if (file.size > 5000000) {
        notify({
          status: "error",
          title: "Error",
          text: "Please, select file with size less then 5MB",
        });
        return;
      }

      const bmp = await createImageBitmap(file);
      const { width, height } = bmp;
      bmp.close();

      if (width !== 786 || height !== 587) {
        notify({
          status: "error",
          title: "Error",
          text: "Image size must be 786 x 587",
        });
        return;
      }

      onFieldChange("image", file);
    };

    const onConfirmImageRemove = () => {
      onFieldChange("image", "");
      setImageUrl("");
      changeRemoveModalConfirmationState(false);
    };

    const onVoucherCsvFileChange = ({
      target,
    }: React.ChangeEvent<HTMLInputElement>) => {
      const file = target.files[0];

      if (file.type !== "text/csv") {
        notify({
          status: "error",
          title: "Error",
          text: "Please, select correct file type .csv",
        });
        return;
      }

      onFieldChange("voucherCsvFile", file);
    };

    useImperativeHandle(ref, () => ({
      resetVoucherCsvFile: () => onFieldChange("voucherCsvFile", ""),
    }));

    return (
      <>
        {isDirty && errors && Object.values(errors).length > 0 && (
          <Card>
            <CardContent>
              <ul className={styles.errorsList}>
                {Object.values(errors).map(i => (
                  <li className={styles.error} key={i}>
                    {i}
                  </li>
                ))}
              </ul>
            </CardContent>
          </Card>
        )}
        {serverErrors && serverErrors.length > 0 && (
          <Card>
            <CardContent>
              <ul className={styles.errorsList}>
                {serverErrors.map(i => (
                  <li className={styles.error} key={i}>
                    {i}
                  </li>
                ))}
              </ul>
            </CardContent>
          </Card>
        )}
        <Card>
          <CardTitle
            title={intl.formatMessage(commonMessages.generalInformations)}
          />
          <CardContent>
            <TextField
              error={!!(isDirty && errors.titleDe)}
              fullWidth
              label={intl.formatMessage({
                id: "WczTA7",
                defaultMessage: "Title",
                description: "title value",
              })}
              name="title"
              helperText="This field have multi language input"
              value={getValueByLang("title")}
              onChange={e =>
                onFieldChange(getObjKeyByLang(e.target.name), e.target.value)
              }
            />
            <FormSpacer />
            <TextField
              error={!!(isDirty && errors.descriptionDe)}
              fullWidth
              multiline
              label={intl.formatMessage({
                id: "K3/Mih",
                defaultMessage: "Description",
                description: "description value",
              })}
              name="description"
              helperText="This field have multi language input"
              value={getValueByLang("description")}
              onChange={e =>
                onFieldChange(getObjKeyByLang(e.target.name), e.target.value)
              }
            />
            <FormSpacer />
            <TextField
              error={!!(isDirty && errors.company)}
              fullWidth
              label={intl.formatMessage({
                id: "awtu7d",
                defaultMessage: "Company",
                description: "company value",
              })}
              name="company"
              value={values.company}
              onChange={e => onFieldChange(e.target.name, e.target.value)}
            />

            <ControlledCheckbox
              checked={values.isActive}
              disabled={false}
              data-test-id="is-active"
              label={intl.formatMessage({
                id: "b8HDmL",
                defaultMessage: "Offer status",
                description: "checkbox",
              })}
              name="isActive"
              onChange={e => onFieldChange(e.target.name, e.target.value)}
            />
          </CardContent>
        </Card>
        <Card>
          <CardTitle
            title="Media"
            toolbar={
              <>
                <Button
                  variant="tertiary"
                  data-test-id="button-upload-image"
                  onClick={() => imagesUpload.current.click()}
                >
                  Upload
                </Button>
                <input
                  className={styles.fileField}
                  onChange={event => handleImageUpload(event.target.files)}
                  type="file"
                  ref={imagesUpload}
                  accept="image/*"
                />
              </>
            }
          />
          <CardContent>
            <p>Image size must be 786 x 587</p>
            {imageUrl ? (
              <div className={styles.imageWrapper}>
                <MediaTile
                  media={{ url: imageUrl as string, alt: "" }}
                  onDelete={changeRemoveModalConfirmationState.bind(null, true)}
                  onEdit={() => imagesUpload.current.click()}
                />
              </div>
            ) : (
              <ImageUpload onImageUpload={handleImageUpload} multiple={false} />
            )}
          </CardContent>
        </Card>
        <Card>
          <CardTitle title="Gradient Colors" />
          <CardContent className={styles.colors}>
            <SketchColorPicker
              className={styles.firstColor}
              label="Start"
              onChange={color => onFieldChange("gradientStart", color)}
              value={values.gradientStart}
            />
            <SketchColorPicker
              label="End"
              onChange={color => onFieldChange("gradientEnd", color)}
              value={values.gradientEnd}
            />
          </CardContent>
        </Card>
        <Card>
          <CardTitle title="Additional Information" />
          <CardContent>
            <Grid variant="uniform">
              <PriceField
                disabled={false}
                error={false}
                label={intl.formatMessage({
                  id: "1shOIS",
                  defaultMessage: "Price",
                  description: "column title",
                })}
                name="price"
                value={`${values.price}`}
                onChange={e => onFieldChange(e.target.name, e.target.value)}
                /* currencySymbol={'$'} */
              />
              <TextField
                error={false}
                fullWidth
                label={intl.formatMessage({
                  id: "64ILbv",
                  defaultMessage: "Shop link",
                  description: "shoplink value",
                })}
                helperText="This field have multi language input"
                name="shopLink"
                value={getValueByLang("shopLink")}
                onChange={e =>
                  onFieldChange(getObjKeyByLang(e.target.name), e.target.value)
                }
              />
            </Grid>
          </CardContent>
        </Card>
        <Card>
          <CardTitle title="Dates" />
          <CardContent>
            <Grid variant="uniform">
              <TextField
                disabled={false}
                error={!!(isDirty && errors.releaseDate)}
                name="releaseDate"
                onChange={e => onFieldChange(e.target.name, e.target.value)}
                label="Release date"
                value={values.releaseDate}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
              <TextField
                disabled={false}
                error={!!(isDirty && errors.releaseDate)}
                name="releaseTime"
                onChange={e => onFieldChange(e.target.name, e.target.value)}
                label="Release hour"
                value={values.releaseTime}
                type="time"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
              <TextField
                disabled={false}
                error={!!(isDirty && errors.expirationDate)}
                name="expirationDate"
                onChange={e => onFieldChange(e.target.name, e.target.value)}
                label="Expiration date"
                value={values.expirationDate}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
              <TextField
                disabled={false}
                error={!!(isDirty && errors.expirationDate)}
                name="expirationTime"
                onChange={e => onFieldChange(e.target.name, e.target.value)}
                label="Expiration hour"
                value={values.expirationTime}
                type="time"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Grid>
            <Hr className={styles.divider} />
            <Grid variant="uniform">
              <TextField
                disabled={false}
                error={!!(isDirty && errors.periodStartDate)}
                name="periodStartDate"
                onChange={e => onFieldChange(e.target.name, e.target.value)}
                label="Period start date"
                value={values.periodStartDate}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
              <TextField
                disabled={false}
                error={!!(isDirty && errors.periodStartDate)}
                name="periodStartTime"
                onChange={e => onFieldChange(e.target.name, e.target.value)}
                label="Period start hour"
                value={values.periodStartTime}
                type="time"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
              <TextField
                disabled={false}
                error={!!(isDirty && errors.periodEndDate)}
                name="periodEndDate"
                onChange={e => onFieldChange(e.target.name, e.target.value)}
                label="Period end date"
                value={values.periodEndDate}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
              <TextField
                disabled={false}
                error={!!(isDirty && errors.periodEndDate)}
                name="periodEndTime"
                onChange={e => onFieldChange(e.target.name, e.target.value)}
                label="Period end hour"
                value={values.periodEndTime}
                type="time"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Grid>
          </CardContent>
        </Card>
        <Card>
          <CardTitle title="Vouchers Codes .csv upload" />
          <CardContent>
            <p className={styles.info}>
              .csv file should include <b>post_title</b> column with voucher
              codes
            </p>
            <div className={styles.uploadWrapper}>
              <div
                className={styles.uploadArea}
                onClick={() => fileUpload.current?.click()}
              >
                {!values.voucherCsvFile
                  ? "Select file, available only .csv"
                  : values.voucherCsvFile.name}
                <input
                  className={styles.input}
                  ref={fileUpload}
                  type="file"
                  accept=".csv"
                  onChange={onVoucherCsvFileChange}
                  multiple={false}
                />
              </div>
            </div>
          </CardContent>
        </Card>
        <ActionDialog
          open={showRemoveModalConfirmation}
          confirmButtonState="default"
          title="Confirm image delete"
          onConfirm={onConfirmImageRemove}
          onClose={changeRemoveModalConfirmationState.bind(null, false)}
        >
          Are you sure, you want to delete image ?
        </ActionDialog>
        <Savebar
          onCancel={() => navigate(cancelUrl)}
          onSubmit={onConfirm}
          onDelete={onRemove}
          state={progressState}
          labels={{ confirm: confirmBtnText }}
          disabled={false}
        />
      </>
    );
  },
);

FlowmilesOfferForm.displayName = "FlowmilesOfferForm";
