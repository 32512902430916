import React from "react";
import { Route, Switch } from "react-router-dom";

import {
  flowmilesPurchasesAddPath,
  flowmilesPurchasesDetailPath,
  flowmilesPurchasesPath,
} from "./urls";
import {
  FlowmilesPurchasesCreate,
  FlowmilesPurchasesDetails,
  FlowmilesPurchasesList,
} from "./views";

export const FlowmilesPurchasesSection: React.FC = () => (
  <Switch>
    <Route
      exact
      path={flowmilesPurchasesPath}
      component={FlowmilesPurchasesList}
    />
    <Route
      exact
      path={flowmilesPurchasesAddPath}
      component={FlowmilesPurchasesCreate}
    />
    <Route
      exact
      path={flowmilesPurchasesDetailPath(":id")}
      component={FlowmilesPurchasesDetails}
    />
  </Switch>
);

export default FlowmilesPurchasesSection;
