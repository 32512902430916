import { FormData } from "./SerialNumberForm";

const createEmptyRequiredError = (field: string) => ({
  __typename: "ChannelError",
  code: "REQUIRED",
  field,
  message: null,
});

const createPositiveNumberError = (field: string) => ({
  __typename: "ChannelError",
  code: "",
  field,
  message: null,
});

export const validateSerialNumberFormData = (data: FormData) => {
  let errors = [];

  if (!data.bluetoothProtocol) {
    errors = [...errors, createEmptyRequiredError("bluetoothProtocol")];
  }

  if (!data.modalName) {
    errors = [...errors, createEmptyRequiredError("modalName")];
  }

  if (!data.requiredNumbers) {
    errors = [...errors, createEmptyRequiredError("requiredNumbers")];
  } else {
    if (Number(data.requiredNumbers) < 1) {
      errors = [...errors, createPositiveNumberError("requiredNumbers")];
    }
  }

  if (!data.serialNumberPrefix) {
    errors = [...errors, createEmptyRequiredError("serialNumberPrefix")];
  }

  if (!data.sku) {
    errors = [...errors, createEmptyRequiredError("sku")];
  }

  return errors;
};
