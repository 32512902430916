import ActionDialog from "@dashboard/components/ActionDialog";
import { TopNav } from "@dashboard/components/AppLayout";
import CardTitle from "@dashboard/components/CardTitle";
import { DetailPageLayout } from "@dashboard/components/Layouts";
import SingleAutocompleteSelectField, {
  SingleAutocompleteChoiceType,
} from "@dashboard/components/SingleAutocompleteSelectField";
import { DEFAULT_INITIAL_SEARCH_DATA } from "@dashboard/config";
import {
  useFlowMileProductDeleteMutation,
  useFlowMileProductDetailQuery,
  useFlowMileProductUpdateMutation,
} from "@dashboard/graphql";
import { getSearchFetchMoreProps } from "@dashboard/hooks/makeTopLevelSearch/utils";
import useNavigator from "@dashboard/hooks/useNavigator";
import useNotifier from "@dashboard/hooks/useNotifier";
import useStateFromProps from "@dashboard/hooks/useStateFromProps";
import { maybe } from "@dashboard/misc";
import useVoucherSearch from "@dashboard/searches/useVoucherSearch";
import createSingleAutocompleteSelectHandler from "@dashboard/utils/handlers/singleAutocompleteSelectChangeHandler";
import { mapEdgesToItems } from "@dashboard/utils/maps";
import { Card, CardContent, makeStyles } from "@material-ui/core";
import { ConfirmButtonTransitionState } from "@saleor/macaw-ui";
import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { RouteComponentProps } from "react-router";

import {
  FlowmilesOfferForm,
  FormData,
  FormRef,
  Info,
  LanguageSwitcher,
  VoucherCodes,
} from "../components";
import {
  mapToFlowmilesOfferFormData,
  mapToFlowmilesOfferServerData,
} from "../mappers";
import { flowmilesOfferPath } from "../urls";

const useStyles = makeStyles(
  () => ({
    error: {
      color: "red",
    },
    errorsList: {
      marginTop: "-15px",
    },
  }),
  { name: "Owner" },
);

function getChoices(
  nodes: Array<{ id: string; code: string }>,
): SingleAutocompleteChoiceType[] {
  return maybe(
    () =>
      nodes.map(node => ({
        label: node.code,
        value: node.id,
      })),
    [],
  );
}

interface FlowmilesOfferDetailsRouteParams {
  id: string;
}

export const FlowmilesOfferDetails: React.FC<
  RouteComponentProps<FlowmilesOfferDetailsRouteParams>
> = ({ match }) => {
  const {
    loadMore: loadMoreVouchers,
    search: searchVouchers,
    result: searchVouchersOpts,
  } = useVoucherSearch({
    variables: DEFAULT_INITIAL_SEARCH_DATA,
  });

  const fetchMoreVouchers = getSearchFetchMoreProps(
    searchVouchersOpts,
    loadMoreVouchers,
  );

  const formRef = useRef<FormRef>();
  const wasPrevUnusedVoucherCodesCalculated = useRef(false);
  const navigate = useNavigator();
  const [language, setLanguage] = useState("de");
  const [prevUnusedVoucherCodes, setPrevUnusedVoucherCodes] = useState(0);
  const [showRemoveModalConfirmation, changeRemoveModalConfirmationState] =
    useState(false);

  const [removeErrors, setRemoveErrors] = useState([]);
  const [updateErrors, setUpdateErrors] = useState([]);
  const [voucherSelectError, setVoucherSelectError] = useState(null);

  const [removeBtnState, setRemoveBtnState] =
    useState<ConfirmButtonTransitionState>("default");
  const [updateBtnState, setUpdateBtnState] =
    useState<ConfirmButtonTransitionState>("default");

  const intl = useIntl();
  const notify = useNotifier();

  const styles = useStyles();

  const { data, refetch } = useFlowMileProductDetailQuery({
    variables: { id: match.params.id },
  });

  const [selectedVoucherDisplay, setSelectedVoucherDisplay] = useStateFromProps(
    data?.flowMileProduct?.voucher?.code || null,
  );

  const [selectedVoucher, setSelectedVoucher] = useStateFromProps(
    data?.flowMileProduct?.voucher?.id || null,
  );

  const voucherChoiceList =
    mapEdgesToItems(searchVouchersOpts?.data?.search) || [];
  const vouchers = getChoices(voucherChoiceList);

  const handleVoucherSelect = createSingleAutocompleteSelectHandler(
    e => {
      setVoucherSelectError(null);
      return setSelectedVoucher(e.target.value);
    },
    setSelectedVoucherDisplay,
    vouchers,
  );

  useEffect(() => {
    if (!wasPrevUnusedVoucherCodesCalculated.current && data) {
      setPrevUnusedVoucherCodes(
        data.flowMileProduct.unusedVoucherCodes?.length || 0,
      );
      wasPrevUnusedVoucherCodesCalculated.current = true;
    }
  }, [data]);

  const [deleteItem] = useFlowMileProductDeleteMutation();
  const [updateItem] = useFlowMileProductUpdateMutation();

  const onSubmit = async (formData: FormData) => {
    if (!selectedVoucher) {
      setVoucherSelectError(
        "Voucher must be selected to make Flow Offer work on storefront.",
      );
      return;
    }
    setUpdateBtnState("loading");
    const input = mapToFlowmilesOfferServerData({
      ...formData,
      voucher: selectedVoucher,
    });
    const { data } = await updateItem({
      variables: { id: match.params.id, input },
    });

    if (data && input.voucherCsvFile) {
      if (
        data.flowMileProductUpdate.flowProduct.unusedVoucherCodes.length ===
        prevUnusedVoucherCodes
      ) {
        notify({
          status: "info",
          title: "Voucher codes",
          text: "No voucher codes where added",
        });
      } else {
        notify({
          status: "info",
          title: "Voucher codes",
          text: `Successfully added ${
            data.flowMileProductUpdate.flowProduct.unusedVoucherCodes.length -
            prevUnusedVoucherCodes
          } voucher codes`,
        });
        setPrevUnusedVoucherCodes(
          data.flowMileProductUpdate.flowProduct.unusedVoucherCodes.length,
        );
      }
    }

    if (
      data.flowMileProductUpdate.errors &&
      data.flowMileProductUpdate.errors.length > 0
    ) {
      setUpdateErrors(data.flowMileProductUpdate.errors.map(i => i.message));
    } else {
      notify({
        status: "success",
        title: "Success",
        text: "Flowmile product successfully updated",
      });
      setUpdateErrors([]);
      refetch();
      formRef.current.resetVoucherCsvFile();
    }
    setUpdateBtnState("default");
  };

  const onRemove = async () => {
    setRemoveBtnState("loading");
    const { data } = await deleteItem({ variables: { id: match.params.id } });

    if (
      data.flowMileProductDelete.errors &&
      data.flowMileProductDelete.errors.length > 0
    ) {
      setRemoveErrors(data.flowMileProductDelete.errors.map(i => i.message));
    } else {
      changeRemoveModalConfirmationState(false);
      notify({
        status: "success",
        title: "Success",
        text: "Flowmile product successfully changed",
      });
      setRemoveErrors([]);
      navigate(flowmilesOfferPath);
    }
  };

  if (!data) {
    return null;
  }

  return (
    <DetailPageLayout>
      <TopNav
        href={flowmilesOfferPath}
        // eslint-disable-next-line formatjs/enforce-id
        title={intl.formatMessage({
          id: "tTRuy8",
          defaultMessage: data.flowMileProduct.title,
          description: "page header",
        })}
      >
        <LanguageSwitcher defaultValue={language} onChange={setLanguage} />
      </TopNav>
      <DetailPageLayout.RightSidebar>
        <Info
          createdAt={data.flowMileProduct.createdAt}
          updatedAt={data.flowMileProduct.updatedAt}
        />
        <Card>
          <CardTitle
            title={intl.formatMessage({
              id: "z6IPUY",
              defaultMessage: "Voucher",
              description: "section header",
            })}
          />
          <CardContent>
            <SingleAutocompleteSelectField
              displayValue={selectedVoucherDisplay}
              error={voucherSelectError}
              helperText={intl.formatMessage({
                id: "g5wfuK",
                defaultMessage: "Select voucher to apply",
              })}
              label={intl.formatMessage({
                id: "9BvYb9",
                defaultMessage: "Voucher",
              })}
              choices={vouchers}
              name="voucher"
              value={selectedVoucher}
              onChange={handleVoucherSelect}
              fetchChoices={searchVouchers}
              data-test-id="voucher"
              {...fetchMoreVouchers}
            />
            {voucherSelectError && (
              <p className={styles.error}>{voucherSelectError}</p>
            )}
          </CardContent>
        </Card>
      </DetailPageLayout.RightSidebar>
      <DetailPageLayout.Content>
        <FlowmilesOfferForm
          ref={formRef}
          serverErrors={updateErrors}
          data={mapToFlowmilesOfferFormData(data)}
          language={language}
          cancelUrl={flowmilesOfferPath}
          confirmBtnText="Update"
          onSubmit={onSubmit}
          progressState={updateBtnState}
          onRemove={changeRemoveModalConfirmationState.bind(null, true)}
        />
        <VoucherCodes
          id={match.params.id}
          isUsed={false}
          title="Unused Voucher Codes"
        />
        <VoucherCodes id={match.params.id} isUsed title="Used Voucher Codes" />
      </DetailPageLayout.Content>
      <ActionDialog
        open={showRemoveModalConfirmation}
        confirmButtonState={removeBtnState}
        title="Confirm delete"
        onConfirm={onRemove}
        onClose={changeRemoveModalConfirmationState.bind(null, false)}
      >
        {removeErrors && removeErrors.length > 0 && (
          <ul className={styles.errorsList}>
            {removeErrors.map(i => (
              <li className={styles.error} key={i}>
                {i}
              </li>
            ))}
          </ul>
        )}
        Are you sure, you want to delete <b>{data.flowMileProduct.title}</b>
      </ActionDialog>
    </DetailPageLayout>
  );
};
