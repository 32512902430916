import { gql } from "@apollo/client";

export const lockReset = gql`
  mutation LockReset($id: ID!) {
    lockReset(id: $id) {
      lock {
        owner {
          id
          user {
            id
            email
            firstName
            lastName
            lastLogin
          }
          createdAt
        }
        users {
          id
          user {
            id
            email
            firstName
            lastName
            lastLogin
          }
          createdAt
          removedAt
        }
        pastOwners {
          id
          user {
            id
            email
          }
          createdAt
          removedAt
        }
        fingerprints {
          id
          nickname
          pointer
          createdAt
          updatedAt
        }
      }
      errors {
        field
        message
      }
    }
  }
`;

export const uploadLockCsv = gql`
  mutation LockCsvUpload($file: Upload!) {
    lockCsvUpload(file: $file) {
      errors {
        field
        message
      }
    }
  }
`;
