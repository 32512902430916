import {
  FlowMileProductDetailQuery,
  FlowProductInput,
} from "@dashboard/graphql";
import moment from "moment-timezone";

import { FormData } from "./components";

const defaultGradientColor = "#fff";

export const mapToFlowmilesOfferFormData = ({
  flowMileProduct: {
    company,
    description,
    endDate,
    linearGradientEndColor,
    linearGradientStartColor,
    image,
    price,
    isActive,
    title,
    shopLink,
    releaseDate,
    descriptionEn,
    descriptionEs,
    descriptionFr,
    descriptionIt,
    shopLinkEn,
    shopLinkEs,
    shopLinkFr,
    shopLinkIt,
    titleEn,
    titleEs,
    titleIt,
    titleFr,
    periodEnd,
    periodStart,
    voucher,
  },
}: FlowMileProductDetailQuery): FormData => ({
  company: company || "",
  descriptionDe: description,
  descriptionEn: descriptionEn || "",
  descriptionEs: descriptionEs || "",
  descriptionFr: descriptionFr || "",
  descriptionIt: descriptionIt || "",
  gradientEnd: linearGradientEndColor || defaultGradientColor,
  gradientStart: linearGradientStartColor || defaultGradientColor,
  image: image || "",
  isActive,
  price: price || 0,
  releaseDate: moment(releaseDate).format("YYYY-MM-DD"),
  releaseTime: moment(releaseDate).format("HH:mm"),
  shopLinkDe: shopLink,
  shopLinkEn: shopLinkEn || "",
  shopLinkEs: shopLinkEs || "",
  shopLinkFr: shopLinkFr || "",
  shopLinkIt: shopLinkIt || "",
  titleDe: title || "",
  titleEn: titleEn || "",
  titleEs: titleEs || "",
  titleIt: titleIt || "",
  titleFr: titleFr || "",
  expirationDate: moment(endDate).format("YYYY-MM-DD"),
  expirationTime: moment(endDate).format("HH:mm"),
  periodEndDate: moment(periodEnd).format("YYYY-MM-DD"),
  periodEndTime: moment(periodEnd).format("HH:mm"),
  periodStartDate: moment(periodStart).format("YYYY-MM-DD"),
  periodStartTime: moment(periodStart).format("HH:mm"),
  voucher: voucher?.id || "",
});

export const mapToFlowmilesOfferServerData = ({
  company,
  descriptionDe,
  descriptionEn,
  descriptionEs,
  descriptionFr,
  descriptionIt,
  gradientEnd,
  gradientStart,
  image,
  price,
  shopLinkDe,
  shopLinkEn,
  shopLinkEs,
  shopLinkFr,
  shopLinkIt,
  titleDe,
  titleEn,
  titleEs,
  titleFr,
  titleIt,
  isActive,
  releaseDate,
  releaseTime,
  expirationDate,
  expirationTime,
  voucherCsvFile,
  periodEndDate,
  periodEndTime,
  periodStartDate,
  periodStartTime,
  voucher,
}: FormData): FlowProductInput => {
  const data: FlowProductInput = {
    voucher,
    company: company || "",
    description: descriptionDe,
    endDate: moment(
      `${expirationDate} ${expirationTime}`,
      "YYYY-MM-DD hh:mm",
    ).toISOString(),
    linearGradientEndColor: gradientEnd,
    linearGradientStartColor: gradientStart,
    price: price || 0,
    isActive,
    title: titleDe,
    shopLink: shopLinkDe,
    releaseDate: moment(
      `${releaseDate} ${releaseTime}`,
      "YYYY-MM-DD hh:mm",
    ).toISOString(),
    descriptionEn: descriptionEn || "",
    descriptionEs: descriptionEs || "",
    descriptionFr: descriptionFr || "",
    descriptionIt: descriptionIt || "",
    shopLinkEn: shopLinkEn || "",
    shopLinkEs: shopLinkEs || "",
    shopLinkFr: shopLinkFr || "",
    shopLinkIt: shopLinkIt || "",
    titleEn: titleEn || "",
    titleEs: titleEs || "",
    titleIt: titleIt || "",
    titleFr: titleFr || "",
    periodStart: moment(
      `${periodStartDate} ${periodStartTime}`,
      "YYYY-MM-DD hh:mm",
    ).toISOString(),
    periodEnd: moment(
      `${periodEndDate} ${periodEndTime}`,
      "YYYY-MM-DD hh:mm",
    ).toISOString(),
  };

  if (voucherCsvFile && voucherCsvFile.type) {
    data.voucherCsvFile = voucherCsvFile;
  }

  if ((image && (image as File).type) || !image) {
    data.image = image;
  }

  return data;
};
