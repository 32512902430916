import CardTitle from "@dashboard/components/CardTitle";
import { useScooterUpdateVerificationCodeMutation } from "@dashboard/graphql";
import useNotifier from "@dashboard/hooks/useNotifier";
import {
  Button,
  Card,
  CardContent,
  makeStyles,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import useRouter from "use-react-router";

const useStyles = makeStyles(
  () => ({
    error: {
      color: "red",
    },
    errorsList: {
      marginTop: 0,
    },
    title: {
      color: "lightseagreen",
    },
    grid: {
      display: "grid",
      gap: "0.8rem",
    },
  }),
  { name: "ChangeVerificationCode" },
);

export const ChangeVerificationCode = () => {
  const notify = useNotifier();
  const { match } = useRouter<{ id: string }>();
  const styles = useStyles();
  const intl = useIntl();

  const [errors, setErrors] = useState<string[]>([]);

  const [values, setValues] = useState({ code: "", repeatCode: "" });
  const [isDirty, setIsDirty] = useState({
    code: false,
    repeatCode: false,
  });

  const [updateVerificationCode] = useScooterUpdateVerificationCodeMutation();

  useEffect(() => {
    const error = [];

    if (isDirty.code) {
      if (!values.code) {
        error.push("Code field is required");
      } else {
        if (values.code.length !== 5) {
          error.push("Code length must be 5 characters");
        }
        if (!/^[A-Za-z0-9]*$/.test(values.code)) {
          error.push("Code must be consisting of letters and numbers only.");
        }
      }
    }

    if (!values.repeatCode && isDirty.repeatCode) {
      error.push("Repeat code field is required");
    }

    if (
      values.repeatCode !== "" &&
      values.code !== "" &&
      values.repeatCode !== values.code
    ) {
      error.push("Code and repeat code field doesn't match");
    }

    setErrors(error);
  }, [values, isDirty]);

  const onSubmit = async () => {
    const { data } = await updateVerificationCode({
      variables: { id: match.params.id, verificationCode: values.code },
    });

    if (
      data.scooterUpdateVerificationCode.errors &&
      data.scooterUpdateVerificationCode.errors.length > 0
    ) {
      setErrors(data.scooterUpdateVerificationCode.errors.map(i => i.message));
    } else {
      notify({
        status: "success",
        title: "Success",
        text: "Verification code successfully changed",
      });
      setErrors([]);
      setValues({ code: "", repeatCode: "" });
      setIsDirty({ code: false, repeatCode: false });
    }
  };

  return (
    <Card>
      <CardTitle title="Change verification code" className={styles.title} />
      <CardContent>
        <div className={styles.grid}>
          <TextField
            error={false}
            label={intl.formatMessage({
              id: "42HafR",
              defaultMessage: "Code",
              description: "Code",
            })}
            name="code"
            value={values.code}
            onChange={e => {
              setValues({ ...values, ...{ code: e.target.value } });
              setIsDirty({ ...isDirty, ...{ code: true } });
            }}
            onFocus={setIsDirty.bind(null, {
              ...isDirty,
              ...{ code: true },
            })}
          />

          <TextField
            error={false}
            label={intl.formatMessage({
              id: "IwhG2/",
              defaultMessage: "Repeat code",
              description: "Repeat code",
            })}
            name="repeatCode"
            value={values.repeatCode}
            onChange={e => {
              setValues({ ...values, ...{ repeatCode: e.target.value } });
              setIsDirty({ ...isDirty, ...{ repeatCode: true } });
            }}
            onFocus={setIsDirty.bind(null, {
              ...isDirty,
              ...{ repeatCode: true },
            })}
          />

          <Button
            disabled={
              !(isDirty.code || isDirty.repeatCode) ? true : errors.length !== 0
            }
            onClick={onSubmit}
            variant="outlined"
          >
            Change
          </Button>
          {!!errors.length && (
            <ul className={styles.errorsList}>
              {errors.map(i => (
                <li className={styles.error} key={i}>
                  {i}
                </li>
              ))}
            </ul>
          )}
        </div>
      </CardContent>
    </Card>
  );
};
