import { TopNav } from "@dashboard/components/AppLayout";
import CardTitle from "@dashboard/components/CardTitle";
import { DateTime } from "@dashboard/components/Date";
import { DetailPageLayout } from "@dashboard/components/Layouts";
import Link from "@dashboard/components/Link";
import NotFoundPage from "@dashboard/components/NotFoundPage";
import TableRowLink from "@dashboard/components/TableRowLink";
import { countries } from "@dashboard/fixtures";
import { useScooterDetailsQuery } from "@dashboard/graphql";
import {
  Card,
  CardContent,
  makeStyles,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { ResponsiveTable } from "@saleor/macaw-ui";
import React, { useEffect, useMemo, useState } from "react";
import { RouteComponentProps } from "react-router";

import { ChangePassword, ChangeVerificationCode, Owner } from "../components";
import { SooterOwnerType } from "../types";
import { fleetListPath } from "../urls";

const useStyles = makeStyles(
  () => ({
    card: {
      maxWidth: "70%",
    },
    spacer: {
      flexGrow: 1,
      borderBottom: "1px dotted rgba(0,0,0,.2)",
      position: "relative",
      bottom: 9,
      marginLeft: 5,
      marginRight: 5,
    },
    line: {
      display: "flex",
      marginTop: 4,
      fontSize: 14,
    },
    name: {},
    value: {},
    list: {
      marginTop: -8,
      marginBottom: 0,
    },
    title: {
      color: "lightseagreen",
    },
    table: {
      minWidth: "1100px",
    },
  }),
  { name: "FleetListView" },
);

const emptyValue = "-";

interface FleetDetailsRouteParams {
  id: string;
}

const distributorCountry = {
  "1039": "Swithland",
  "4000": "Germany",
};

export const FleetDetailsView: React.FC<
  RouteComponentProps<FleetDetailsRouteParams>
> = ({ match }) => {
  const styles = useStyles();
  const [owner, setOwner] = useState<null | SooterOwnerType>(null);

  const { data, loading } = useScooterDetailsQuery({
    variables: { id: match.params.id },
  });

  const country = useMemo(() => {
    if (!data) {
      return "";
    }

    return (
      countries.find(
        i => i.code === data.scooter.owner?.user?.geolocationCountry,
      )?.name || emptyValue
    );
  }, [data]);

  useEffect(() => {
    if (data) {
      setOwner(data.scooter.owner);
    }
  }, [data]);

  if (loading) {
    return null;
  }

  if (!(data && data.scooter)) {
    return <NotFoundPage backHref={fleetListPath} />;
  }

  return (
    <DetailPageLayout>
      <TopNav
        href={fleetListPath}
        title={
          <>
            Serial number: <b>{data.scooter.serialNumber}</b>
          </>
        }
      ></TopNav>
      <DetailPageLayout.RightSidebar>
        <Owner owner={owner} onOwnerDelete={setOwner.bind(null, null)} />
        <ChangeVerificationCode />
        <ChangePassword />
      </DetailPageLayout.RightSidebar>
      <DetailPageLayout.Content>
        <Card className={styles.card}>
          <CardTitle title="Scooter information" className={styles.title} />
          <CardContent>
            <ul className={styles.list}>
              <li className={styles.line}>
                <span className={styles.name}>Model</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.scooter.scooterModel || emptyValue}
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>Manufacturer number</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.scooter.manufacturerNumber || emptyValue}
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>Assembly batch</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.scooter.assemblyBatch || emptyValue}
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>Creation date</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.scooter.createdAt ? (
                    <DateTime date={data.scooter.createdAt} plain />
                  ) : (
                    emptyValue
                  )}
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>Date of first registration</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.scooter.firstRegistration ? (
                    <DateTime date={data.scooter.firstRegistration} plain />
                  ) : (
                    emptyValue
                  )}
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>Distance in kilometers</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.scooter.totalMileage || emptyValue}
                </span>
              </li>
            </ul>
          </CardContent>
        </Card>
        <Card className={styles.card}>
          <CardTitle title="Customer data" className={styles.title} />
          <CardContent>
            <ul className={styles.list}>
              <li className={styles.line}>
                <span className={styles.name}>Scooter nickname</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.scooter.name || emptyValue}
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>Scooter password</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.scooter.password ? "Set" : "Not set"}
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>Verification ID</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  <b>{data.scooter.verificationCode || emptyValue}</b>
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>Current owner ID</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>{owner?.id || emptyValue}</span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>Current owner email</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  <b>
                    {owner?.user.email ? (
                      <Link
                        href={`/customers/${owner.user.id}`}
                        target="_blank"
                      >
                        {owner.user.email}
                      </Link>
                    ) : (
                      emptyValue
                    )}
                  </b>
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>Last login</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  <b>
                    {owner?.user.lastLogin ? (
                      <DateTime date={owner?.user.lastLogin} plain />
                    ) : (
                      emptyValue
                    )}
                  </b>
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>Scooter country</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>{country}</span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>Max speed setting</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.scooter.speedLimit || emptyValue}
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>Owner change date</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.scooter.lastOwnerChange ? (
                    <DateTime date={data.scooter.lastOwnerChange} plain />
                  ) : (
                    emptyValue
                  )}
                </span>
              </li>
            </ul>
          </CardContent>
        </Card>
        <Card className={styles.card}>
          <CardTitle title="Technical data" className={styles.title} />
          <CardContent>
            <ul className={styles.list}>
              <li className={styles.line}>
                <span className={styles.name}>SKU</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.scooter.sku || emptyValue}
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>MAC</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.scooter.mac || emptyValue}
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>CPU</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.scooter.cpu || emptyValue}
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>Engine</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.scooter.engine || emptyValue}
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>Engine ID</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.scooter.engineId || emptyValue}
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>Battery version</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.scooter.batteryVersion || emptyValue}
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>Battery ID</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.scooter.batteryId || emptyValue}
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>Controller</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.scooter.controller || emptyValue}
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>Controller firmware</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.scooter.controllerFirmware || emptyValue}
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>CPU firmware</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.scooter.cpuFirmware || emptyValue}
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>Bluetooth protocol</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.scooter.bluetoothProtocol || emptyValue}
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>Bluetooth number</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.scooter.bluetoothNumber || emptyValue}
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>BOM reference ID</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  Not available, in development
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>End of Warranty date</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.scooter.endOfWarrantyDate || emptyValue}
                </span>
              </li>
            </ul>
          </CardContent>
        </Card>
        <Card className={styles.card}>
          <CardTitle
            title="Customer service history"
            className={styles.title}
          />
          <CardContent>
            <ul className={styles.list}>
              <li className={styles.line}>
                <span className={styles.name}>Scooter lock</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.scooter.lastScooterLockAt ? (
                    <DateTime date={data.scooter.lastScooterLockAt} plain />
                  ) : (
                    emptyValue
                  )}
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>Battery lock</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.scooter.lastBatteryLockAt ? (
                    <DateTime date={data.scooter.lastBatteryLockAt} plain />
                  ) : (
                    emptyValue
                  )}
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>Headlight</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.scooter.lastHeadlightAt ? (
                    <DateTime date={data.scooter.lastHeadlightAt} plain />
                  ) : (
                    emptyValue
                  )}
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>Display</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.scooter.lastDisplayAt ? (
                    <DateTime date={data.scooter.lastDisplayAt} plain />
                  ) : (
                    emptyValue
                  )}
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>Speed mode</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.scooter.lastSpeedModeAt ? (
                    <DateTime date={data.scooter.lastSpeedModeAt} plain />
                  ) : (
                    emptyValue
                  )}
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>Maximum speed</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.scooter.lastMaximumSpeedAt ? (
                    <DateTime date={data.scooter.lastMaximumSpeedAt} plain />
                  ) : (
                    emptyValue
                  )}
                </span>
              </li>
            </ul>
          </CardContent>
        </Card>
        <Card className={styles.card}>
          <CardTitle title="Transportation data" className={styles.title} />
          <CardContent>
            <ul className={styles.list}>
              <li className={styles.line}>
                <span className={styles.name}>Transportation batch</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.scooter.transportationBatch || emptyValue}
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>Transportation mode</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.scooter.transportationMode || emptyValue}
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>Container number</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.scooter.containerNo || emptyValue}
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>Ex-work</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.scooter.exwork || emptyValue}
                </span>
              </li>
            </ul>
          </CardContent>
        </Card>
        <Card className={styles.card}>
          <CardTitle title="Selling data" className={styles.title} />
          <CardContent>
            <ul className={styles.list}>
              <li className={styles.line}>
                <span className={styles.name}>Distributor ID</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.scooter?.retailer?.distributorId || emptyValue}
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>Distributor name</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.scooter?.retailer?.distributorName || emptyValue}
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>Distributor country</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.scooter.retailer?.retailerId
                    ? distributorCountry[data.scooter.retailer.retailerId]
                    : emptyValue}
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>Distributor purchase date</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  Not available, in development
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>Delivery number</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.scooter.deliveryNo || emptyValue}
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>Delivery date</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.scooter.deliveryDate ? (
                    <DateTime date={data.scooter.deliveryDate} plain />
                  ) : (
                    emptyValue
                  )}
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>Retailer ID</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.scooter?.retailer?.retailerId || emptyValue}
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>Distributor selling date</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.scooter.distributorSellingDate ? (
                    <DateTime
                      date={data.scooter.distributorSellingDate}
                      plain
                    />
                  ) : (
                    emptyValue
                  )}
                </span>
              </li>
            </ul>
          </CardContent>
        </Card>
        <Card>
          <CardTitle title="History" />
          <CardContent>
            <ResponsiveTable className={styles.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Owner change ID</TableCell>
                  <TableCell>Owner change Email</TableCell>
                  <TableCell>Owner change distance KM</TableCell>
                  <TableCell>Owner change confirmation document</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.scooter.pastOwners &&
                  data?.scooter.pastOwners.length > 0 && (
                    <>
                      {data.scooter.pastOwners.map(
                        ({ document, removedTotalMileage, id, user }) => (
                          <TableRowLink hover={false}>
                            <TableCell>{id}</TableCell>
                            <TableCell>
                              <Link
                                href={`/customers/${user.id}`}
                                target="_blank"
                              >
                                {user.email}
                              </Link>
                            </TableCell>
                            <TableCell>{removedTotalMileage}</TableCell>
                            <TableCell>
                              {document && (
                                <Link download href={document} target="_blank">
                                  Document
                                </Link>
                              )}
                              {!document && "No data"}
                            </TableCell>
                          </TableRowLink>
                        ),
                      )}
                    </>
                  )}
                {(!data?.scooter.pastOwners ||
                  data.scooter.pastOwners.length === 0) && (
                  <TableRowLink hover={false}>
                    <TableCell colSpan={4}>No history found</TableCell>
                  </TableRowLink>
                )}
              </TableBody>
            </ResponsiveTable>
          </CardContent>
        </Card>
      </DetailPageLayout.Content>
    </DetailPageLayout>
  );
};
