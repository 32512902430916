import React from "react";
import { Route, Switch } from "react-router-dom";

import {
  flowmilesOfferAddPath,
  flowmilesOfferDetailPath,
  flowmilesOfferPath,
} from "./urls";
import {
  FlowmilesOfferCreate,
  FlowmilesOfferDetails,
  FlowmilesOfferList,
} from "./views";

export const FlowmilesOffersSection: React.FC = () => (
  <Switch>
    <Route exact path={flowmilesOfferPath} component={FlowmilesOfferList} />
    <Route
      exact
      path={flowmilesOfferAddPath}
      component={FlowmilesOfferCreate}
    />
    <Route
      exact
      path={flowmilesOfferDetailPath(":id")}
      component={FlowmilesOfferDetails}
    />
  </Switch>
);

export default FlowmilesOffersSection;
