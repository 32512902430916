import {
  LanguageCodeEnum,
  useEmailTemplateTranslationDetailsQuery,
  useUpdateEmailTemplateTranslationsMutation,
} from "@dashboard/graphql";
import useNavigator from "@dashboard/hooks/useNavigator";
import useNotifier from "@dashboard/hooks/useNotifier";
import useShop from "@dashboard/hooks/useShop";
import { commonMessages } from "@dashboard/intl";
import { extractMutationErrors, getStringOrPlaceholder } from "@dashboard/misc";
import { stringifyQs } from "@dashboard/utils/urls";
import React from "react";
import { useIntl } from "react-intl";

import TranslationsEmailTemplagePage from "../components/TranslationsEmailTemplagePage";
import { TranslationField, TranslationInputFieldName } from "../types";
import { getParsedTranslationInputData } from "../utils";

export interface TranslationsEmailTemplateQueryParams {
  activeField: string;
}
export interface TranslationsEmailTemplateProps {
  id: string;
  languageCode: LanguageCodeEnum;
  params: TranslationsEmailTemplateQueryParams;
}

const TranslationsEmailTemplate: React.FC<TranslationsEmailTemplateProps> = ({
  id,
  languageCode,
  params,
}) => {
  const navigate = useNavigator();
  const notify = useNotifier();
  const shop = useShop();
  const intl = useIntl();

  const emailTemplateTranslations = useEmailTemplateTranslationDetailsQuery({
    variables: { id, language: languageCode },
  });

  const [updateTranslations, updateTranslationsOpts] =
    useUpdateEmailTemplateTranslationsMutation({
      onCompleted: data => {
        if (data.emailTemplateTranslate.errors.length === 0) {
          emailTemplateTranslations.refetch();
          notify({
            status: "success",
            text: intl.formatMessage(commonMessages.savedChanges),
          });
          navigate("?", { replace: true });
        }
      },
    });

  const onEdit = (field: string) =>
    navigate(
      "?" +
        stringifyQs({
          activeField: field,
        }),
      { replace: true },
    );

  const onDiscard = () => {
    navigate("?", { replace: true });
  };

  const translation = emailTemplateTranslations?.data?.translation;

  const translationData =
    translation?.__typename === "EmailTemplateTranslatableContent"
      ? translation
      : null;

  const handleSubmit = (
    { name: fieldName }: TranslationField<TranslationInputFieldName>,
    data: string,
  ) => {
    const input = getParsedTranslationInputData({
      fieldName,
      data,
    });
    const isSubject = getStringOrPlaceholder(
      translationData?.emailTemplate.name,
    ).endsWith("subject");

    if (isSubject) {
      input.value = input.value.replace(/(\r\n|\n|\r)/gm, "");
    }

    return extractMutationErrors(
      updateTranslations({
        variables: {
          id,
          input,
          language: languageCode,
        },
      }),
    );
  };

  return (
    <TranslationsEmailTemplagePage
      translationId={id}
      activeField={params.activeField}
      disabled={
        emailTemplateTranslations.loading || updateTranslationsOpts.loading
      }
      languages={shop?.languages || []}
      languageCode={languageCode}
      saveButtonState={updateTranslationsOpts.status}
      onEdit={onEdit}
      onDiscard={onDiscard}
      onSubmit={handleSubmit}
      data={translationData}
    />
  );
};
TranslationsEmailTemplate.displayName = "TranslationsEmailTemplate";
export default TranslationsEmailTemplate;
