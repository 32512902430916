import ActionDialog from "@dashboard/components/ActionDialog";
import { TopNav } from "@dashboard/components/AppLayout/TopNav";
import ButtonWithSelect from "@dashboard/components/ButtonWithSelect";
import DeleteFilterTabDialog from "@dashboard/components/DeleteFilterTabDialog";
import { FilterElement } from "@dashboard/components/Filter/types";
import FilterBar from "@dashboard/components/FilterBar";
import { DetailPageLayout } from "@dashboard/components/Layouts";
import SaveFilterTabDialog, {
  SaveFilterTabDialogFormData,
} from "@dashboard/components/SaveFilterTabDialog";
import TableRowLink from "@dashboard/components/TableRowLink";
import {
  useScooterCsvExportMutation,
  useScooterRemoveOwnersMutation,
  useScootersQuery,
} from "@dashboard/graphql";
import useNotifier from "@dashboard/hooks/useNotifier";
import {
  Button,
  Card,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@material-ui/core";
import { ConfirmButtonTransitionState } from "@saleor/macaw-ui";
import { Box } from "@saleor/macaw-ui/next";
import moment from "moment-timezone";
import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { SavedFilterItemType } from "../types";
import { fleetPath } from "../urls";

const useStyles = makeStyles(
  theme => ({
    downloadWrapper: {
      display: "flex",
      alignItems: "center",
      padding: "6px 0",
      borderBottom: "1px solid rgb(234, 234, 234)",
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    fileUpload: {
      display: "none",
    },
    downloadText: {
      opacity: "0.6",
    },
    downloadBtn: {
      marginRight: 10,
    },
    tableRow: {
      cursor: "pointer",
    },
  }),
  { name: "FleetListView" },
);

const filtersData = [
  {
    active: false,
    label: "Serial Number",
    multiple: false,
    type: "text",
    name: "serialNumber",
    value: [""],
  },
  {
    active: false,
    label: "SKU",
    multiple: false,
    type: "text",
    name: "sku",
    value: [""],
  },
  {
    active: false,
    label: "Bluetooth protocol",
    multiple: false,
    type: "text",
    name: "bluetoothProtocol",
    value: [""],
  },
  {
    active: false,
    label: "Bom reference ID (Not available, in development)",
    multiple: false,
    type: "text",
    name: "bomReferenceId",
    value: [""],
  },
];

const LOCAL_STORAGE_KEY = "fleetFilters";
const itemsPerPage = 100;

export const FleetListView = () => {
  const styles = useStyles();
  const inputCSVFileRef = useRef(null);
  const notify = useNotifier();

  const [search, setSearch] = useState("");
  const [filter, setFilter] =
    useState<Array<FilterElement<string>>>(filtersData);
  const [currentTab, setCurrentTab] = useState(0);
  const [tabs, setTabs] = useState([]);
  const [saveFilterModalState, setSaveFilterModalState] = useState(false);
  const [removeOwnersModalState, setRemoveOwnersModalState] = useState(false);
  const [deleteFilterModalState, setDeleteFilterModalState] = useState(false);
  const [savedFilters, setSavedFilters] = useState<SavedFilterItemType[]>([]);
  const [ownersRemoveFileError, setOwnersRemoveFileError] = useState<
    null | string
  >(null);
  const [ownersRemoveFile, setOwnersRemoveFile] = useState<null | File>(null);
  const [removeOwnersBtnState, setRemoveOwnersBtnState] =
    useState<ConfirmButtonTransitionState>("default");

  const [currentPage, setCurrentPage] = useState(0);

  const queryVariables = React.useMemo(() => {
    const result: any = {};

    if (search) {
      result.search = search;
    }

    if (filter) {
      const active = filter.filter(i => i.active);
      if (active && active.length) {
        active.forEach(i => (result[i.name] = i.value[0]));
      }
    }

    return result;
  }, [search, filter]);

  const isFiltersSelected = React.useMemo(
    () => queryVariables && Object.keys(queryVariables).length > 0,
    [queryVariables],
  );

  const intl = useIntl();

  const [exportCsv] = useScooterCsvExportMutation({
    onCompleted: data => {
      if (data.scooterCsvExport && data.scooterCsvExport.errors.length === 0) {
        const csvContent = atob(data.scooterCsvExport.csvData);
        const blob = new Blob([csvContent], {
          type: "data:application/octet-stream;base64",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("href", url);
        a.setAttribute(
          "download",
          `Filtered scooters(${moment().format(
            "dddd, MMMM Do YYYY, h:mm:ss a",
          )}).csv`,
        );
        a.click();
      }
    },
  });

  useEffect(() => {
    const result = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (result) {
      const data = JSON.parse(result) as SavedFilterItemType[];
      setTabs(data.map(i => i.name));
      setSavedFilters(data);
    }
  }, []);

  const { data, loading, refetch } = useScootersQuery({
    displayLoader: true,
    variables: { filter: queryVariables, first: itemsPerPage },
  });

  const [scooterRemoveOwners] = useScooterRemoveOwnersMutation();

  const onPageChange = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    page: number,
  ) => {
    if (page > currentPage) {
      refetch({
        before: undefined,
        first: itemsPerPage,
        last: undefined,
        after: data.scooters.pageInfo.endCursor,
      });
    }

    if (page < currentPage) {
      refetch({
        before: data.scooters.pageInfo.startCursor,
        first: undefined,
        last: itemsPerPage,
        after: undefined,
      });
    }

    setCurrentPage(page);
  };

  const handleTabSave = ({ name }: SaveFilterTabDialogFormData) => {
    setTabs([...tabs, ...[name]]);

    const newItem: SavedFilterItemType = {
      data: {},
      name,
    };

    if (search) {
      newItem.data.query = search;
    }

    if (filter && filter.length) {
      filter.forEach(i => (newItem.data[i.name] = i.value[0]));
    }

    const filtersToUpdate = [...savedFilters, ...[newItem]];

    setSavedFilters(filtersToUpdate);
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(filtersToUpdate));
    setSaveFilterModalState(false);
  };

  const toNewTab = () => {
    setCurrentTab(tabs.length + 1);
  };

  const handleTabDelete = () => {
    setFilter(filtersData);
    setSearch("");
    const name = tabs[currentTab - 1];
    setCurrentTab(0);
    setTabs(tabs.filter(i => i !== name));
    const newFilters = savedFilters.filter(i => i.name !== name);
    setSavedFilters(newFilters);
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newFilters));
    setDeleteFilterModalState(false);
  };

  const onAll = () => {
    setFilter(filtersData);
    setSearch("");
    setCurrentTab(0);
  };

  const onFilterChange = (data: Array<FilterElement<string>>) => {
    setFilter(data);
    toNewTab();
  };

  const onSearchChange = (value: string) => {
    setSearch(value);
    toNewTab();
  };

  const onTabChange = (tab: number) => {
    const { data } = savedFilters[tab - 1];

    if (data) {
      setSearch(data.query || "");
      setFilter(
        filtersData.map(i => {
          if (data[i.name]) {
            return { ...i, ...{ active: true, value: [data[i.name], ""] } };
          }

          return i;
        }),
      );
    }

    setCurrentTab(tab);
  };

  const onSelectFileRemoveOwners = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOwnersRemoveFileError(null);
    const file = e.target.files[0];

    if (file.type !== "text/csv") {
      setOwnersRemoveFileError("Please, select correct file type .csv");
      return;
    }

    setOwnersRemoveFile(file);
  };

  const onRemoveOwners = async () => {
    setOwnersRemoveFileError(null);
    setRemoveOwnersBtnState("loading");
    if (ownersRemoveFile === null) {
      setOwnersRemoveFileError("Please, select .csv file");
      setRemoveOwnersBtnState("default");
      return;
    }

    const { data } = await scooterRemoveOwners({
      variables: {
        file: ownersRemoveFile,
      },
    });

    if (
      data &&
      data.scooterRemoveOwners &&
      !data.scooterRemoveOwners.errors.length
    ) {
      inputCSVFileRef.current.value = "";
      setOwnersRemoveFileError(null);
      setOwnersRemoveFile(null);
      setRemoveOwnersBtnState("default");
      setRemoveOwnersModalState(false);
      notify({
        status: "success",
        title: "Success",
        text: "File successfully loaded",
      });
    }
  };

  return (
    <DetailPageLayout gridTemplateColumns={1} withSavebar={false}>
      <TopNav title="Scooter Management">
        <ButtonWithSelect
          onClick={setRemoveOwnersModalState.bind(null, true)}
          options={[]}
          data-test-id="create-flowmiles-shop"
        >
          <FormattedMessage
            id="L/s7Uv"
            defaultMessage="Remove owners by .csv file"
            description="button"
          />
        </ButtonWithSelect>
      </TopNav>
      <DetailPageLayout.Content>
        <FilterBar
          allTabLabel="All scooter"
          currentTab={currentTab}
          filterStructure={filter}
          initialSearch={search}
          onAll={onAll}
          onFilterChange={onFilterChange}
          onSearchChange={onSearchChange}
          onTabChange={onTabChange}
          onTabDelete={setDeleteFilterModalState.bind(null, true)}
          onTabSave={setSaveFilterModalState.bind(null, true)}
          searchPlaceholder="Search scooter"
          tabs={tabs}
        />
        <Card className={styles.downloadWrapper}>
          <Button
            className={styles.downloadBtn}
            disabled={!isFiltersSelected}
            variant="outlined"
            size="small"
            onClick={exportCsv.bind(null, {
              variables: { scooterFilter: queryVariables },
            })}
          >
            Export to .CSV
          </Button>
          <p className={styles.downloadText}>
            For downloading list of scooters, please, select filters, if filters
            are not selected, button will be disabled
          </p>
        </Card>
        <Box paddingX={9} __maxWidth={"100%"} margin="auto">
          <Table size={"small"}>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>ID</TableCell>
                <TableCell>Serial Number</TableCell>
                <TableCell>Model</TableCell>
                <TableCell>Verification Code</TableCell>
                <TableCell>Owner</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.scooters &&
                data.scooters.edges.length > 0 &&
                data.scooters.edges.map(({ node }, i) => (
                  <TableRowLink
                    className={styles.tableRow}
                    key={node.id}
                    href={node && fleetPath(node.id)}
                  >
                    <TableCell
                      style={{
                        maxWidth: "10px",
                        minWidth: "10px",
                        width: "10px",
                      }}
                    >
                      {i + 1 + currentPage * 100}
                    </TableCell>
                    <TableCell>{node.id}</TableCell>
                    <TableCell>{node.serialNumber}</TableCell>
                    <TableCell>{node.scooterModel}</TableCell>
                    <TableCell>{node.verificationCode}</TableCell>
                    <TableCell>{node.owner?.user.email}</TableCell>
                  </TableRowLink>
                ))}
              {data &&
                data.scooters &&
                data.scooters.edges.length === 0 &&
                !loading && (
                  <TableRowLink>
                    <TableCell colSpan={6}>No fleets found</TableCell>
                  </TableRowLink>
                )}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={data?.scooters?.totalCount || 0}
            onPageChange={onPageChange}
            page={currentPage}
            rowsPerPage={itemsPerPage}
            rowsPerPageOptions={[itemsPerPage]}
          />
        </Box>
      </DetailPageLayout.Content>
      <SaveFilterTabDialog
        open={saveFilterModalState}
        confirmButtonState="default"
        onClose={setSaveFilterModalState.bind(null, false)}
        onSubmit={handleTabSave}
      />
      <DeleteFilterTabDialog
        open={deleteFilterModalState}
        confirmButtonState="default"
        onClose={setDeleteFilterModalState.bind(null, false)}
        onSubmit={handleTabDelete}
        tabName={tabs[currentTab - 1]}
      />
      <ActionDialog
        open={removeOwnersModalState}
        confirmButtonState={removeOwnersBtnState}
        title="Remove scooters owner by .csv file"
        confirmButtonLabel="Confirm owners remove"
        onClose={setRemoveOwnersModalState.bind(null, false)}
        onConfirm={onRemoveOwners}
      >
        <input
          ref={inputCSVFileRef}
          className={styles.fileUpload}
          type="file"
          onChange={onSelectFileRemoveOwners}
          accept=".csv"
          multiple={false}
        />
        <TextField
          error={ownersRemoveFileError !== null}
          fullWidth
          label={intl.formatMessage({
            id: "kuQd2s",
            defaultMessage: "Select .csv file",
            description: "description value",
          })}
          type="text"
          helperText={
            ownersRemoveFileError === null ? "" : ownersRemoveFileError
          }
          value={ownersRemoveFile?.name || ""}
          onClick={() => inputCSVFileRef.current.click()}
        />
      </ActionDialog>
    </DetailPageLayout>
  );
};
