import { TopNav } from "@dashboard/components/AppLayout";
import ButtonWithSelect from "@dashboard/components/ButtonWithSelect";
import { DateTime } from "@dashboard/components/Date";
import { DetailPageLayout } from "@dashboard/components/Layouts";
import Link from "@dashboard/components/Link";
import TableRowLink from "@dashboard/components/TableRowLink";
import { customerPath } from "@dashboard/customers/urls";
import { useFlowMilePurchasesListQuery } from "@dashboard/graphql";
import useNavigator from "@dashboard/hooks/useNavigator";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import { Box } from "@saleor/macaw-ui/next";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

import {
  flowmilesPurchasesAddPath,
  flowmilesPurchasesDetailPath,
} from "../urls";

const useStyles = makeStyles(
  () => ({
    tableRow: {
      cursor: "pointer",
    },
  }),
  { name: "FlowmilesPurchasesList" },
);

const itemsPerPage = 100;

export const FlowmilesPurchasesList = () => {
  const navigate = useNavigator();
  const [currentPage, setCurrentPage] = useState(0);

  const styles = useStyles();

  const { data, loading, refetch } = useFlowMilePurchasesListQuery({
    displayLoader: true,
    variables: { first: itemsPerPage },
  });

  const onPageChange = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    page: number,
  ) => {
    if (page > currentPage) {
      refetch({
        before: undefined,
        first: itemsPerPage,
        last: undefined,
        after: data.flowMilePurchases.pageInfo.endCursor,
      });
    }

    if (page < currentPage) {
      refetch({
        before: data.flowMilePurchases.pageInfo.startCursor,
        first: undefined,
        last: itemsPerPage,
        after: undefined,
      });
    }

    setCurrentPage(page);
  };

  const onRowClick = (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    id: string,
  ) => {
    if ((event.target as any).classList.value.includes("ignore-click")) {
      return event.stopPropagation();
    }
    navigate(flowmilesPurchasesDetailPath(id));
  };

  if (!data) {
    return null;
  }

  return (
    <DetailPageLayout gridTemplateColumns={1} withSavebar={false}>
      <TopNav title="FlowMiles purchases">
        <ButtonWithSelect
          onClick={() => navigate(flowmilesPurchasesAddPath)}
          options={[]}
          data-test-id="create-flowmiles-purchases"
        >
          <FormattedMessage
            id="arV3gC"
            defaultMessage="Create FlowMiles purchases"
            description="button"
          />
        </ButtonWithSelect>
      </TopNav>
      <DetailPageLayout.Content>
        <Box paddingX={9} __maxWidth={"100%"} margin="auto">
          <Table size={"small"}>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Created at</TableCell>
                <TableCell>Updated at</TableCell>
                <TableCell>State</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Product</TableCell>
                <TableCell>Voucher code</TableCell>
                <TableCell>User</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.flowMilePurchases &&
                data.flowMilePurchases.edges.length > 0 &&
                data.flowMilePurchases.edges.map(({ node }) => (
                  <TableRow
                    className={styles.tableRow}
                    key={node.id}
                    hover
                    onClick={e => onRowClick(e, node.id)}
                  >
                    <TableCell>{node.id}</TableCell>
                    <TableCell>
                      <DateTime date={node.createdAt} plain />
                    </TableCell>
                    <TableCell>
                      <DateTime date={node.updatedAt} plain />
                    </TableCell>
                    <TableCell>{node.state}</TableCell>
                    <TableCell>{node.price}</TableCell>
                    <TableCell>
                      <Link
                        className="ignore-click"
                        /* href={flowmilesShopDetailPath(node.product.id)} */
                        target="_blank"
                      >
                        Open product
                      </Link>
                    </TableCell>
                    <TableCell>{node.voucherCode}</TableCell>
                    <TableCell>
                      <Link
                        className="ignore-click"
                        href={customerPath(node.user.id)}
                        target="_blank"
                      >
                        {node.user.email}
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              {data.flowMilePurchases &&
                data.flowMilePurchases.edges.length === 0 &&
                !loading && (
                  <TableRowLink>
                    <TableCell colSpan={6}>
                      No flowmiles purchases found
                    </TableCell>
                  </TableRowLink>
                )}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={data.flowMilePurchases?.totalCount || 0}
            onPageChange={onPageChange}
            page={currentPage}
            rowsPerPage={itemsPerPage}
            rowsPerPageOptions={[itemsPerPage]}
          />
        </Box>
      </DetailPageLayout.Content>
    </DetailPageLayout>
  );
};
