import { gql } from "@apollo/client";
import {
  SearchVouchersDocument,
  SearchVouchersQuery,
  SearchVouchersQueryVariables,
} from "@dashboard/graphql";
import makeTopLevelSearch from "@dashboard/hooks/makeTopLevelSearch";

export const searchVouchers = gql`
  query SearchVouchers($after: String, $first: Int!, $query: String!) {
    search: vouchers(after: $after, first: $first, filter: { search: $query }) {
      edges {
        node {
          id
          code
          name
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
`;

export default makeTopLevelSearch<
  SearchVouchersQuery,
  SearchVouchersQueryVariables
>(SearchVouchersDocument);
