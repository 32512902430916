import CardTitle from "@dashboard/components/CardTitle";
import useNavigator from "@dashboard/hooks/useNavigator";
import { Card, CardContent } from "@material-ui/core";
import { Button } from "@saleor/macaw-ui";
import React from "react";

export const ProductTranslations = ({ productId }) => {
  const navigate = useNavigator();
  const languages = ["DE", "IT", "FR"];

  const translate = (lang: string) => {
    navigate(`/translations/${lang}/products/${productId}`);
  };

  return (
    <Card>
      <CardTitle title="Translations" />
      <CardContent>
        <div style={{ display: "flex", gap: "10px" }}>
          {languages.map((lang, i) => (
            <Button
              key={`language-button-${i}`}
              variant="secondary"
              onClick={() => translate(lang)}
            >
              {lang}
            </Button>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

ProductTranslations.displayName = "Product translations";
export default ProductTranslations;
