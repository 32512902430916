import CardTitle from "@dashboard/components/CardTitle";
import { useUpdateCustomerPasswordMutation } from "@dashboard/graphql";
import useNotifier from "@dashboard/hooks/useNotifier";
import {
  Button,
  Card,
  CardContent,
  makeStyles,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

const useStyles = makeStyles(
  () => ({
    error: {
      color: "red",
    },
    errorsList: {
      marginTop: 0,
    },
    wrapper: {
      display: "flex",
      justifyContent: "space-between",
      columnGap: 20,
    },
    input: {
      flexGrow: 5,
    },
    button: {
      flexGrow: 2,
    },
  }),
  { name: "CustomerChangePassword" },
);

interface CustomerChangePasswordProps {
  id: string;
}

const CustomerChangePassword: React.FC<CustomerChangePasswordProps> = ({
  id,
}) => {
  const styles = useStyles();
  const notify = useNotifier();
  const intl = useIntl();

  const [errors, setErrors] = useState<string[]>([]);

  const [values, setValues] = useState({ password: "", repeatPassword: "" });
  const [isDirty, setIsDirty] = useState({
    password: false,
    repeatPassword: false,
  });

  const [updatePassword] = useUpdateCustomerPasswordMutation();

  useEffect(() => {
    const error = [];

    if (isDirty.password) {
      if (!values.password) {
        error.push("Password field is required");
      } else {
        if (values.password.length <= 8) {
          error.push("Password length must be more than 8 symbols");
        }
        if (values.password.search(/[a-z]/i) < 0) {
          error.push("Password must contain at least one letter");
        }
        if (values.password.search(/[0-9]/) < 0) {
          error.push("Password must contain at least one digit");
        }
        if (
          values.password.search(
            /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).*$/,
          ) < 0
        ) {
          error.push("Password must contain at least one Special Symbol");
        }
      }
    }

    if (!values.repeatPassword && isDirty.repeatPassword) {
      error.push("Repeat password field is required");
    }

    if (
      values.repeatPassword !== "" &&
      values.password !== "" &&
      values.repeatPassword !== values.password
    ) {
      error.push("Password and repeat password field doesn't match");
    }

    setErrors(error);
  }, [values, isDirty]);

  const onSubmit = async () => {
    const { data } = await updatePassword({
      variables: { id, password: values.password },
    });

    if (
      data.customerUpdatePassword.errors &&
      data.customerUpdatePassword.errors.length > 0
    ) {
      setErrors(data.customerUpdatePassword.errors.map(i => i.message));
    } else {
      notify({
        status: "success",
        title: "Success",
        text: "Password successfully changed",
      });
      setErrors([]);
      setValues({ password: "", repeatPassword: "" });
      setIsDirty({ password: false, repeatPassword: false });
    }
  };

  return (
    <Card>
      <CardTitle title="Manually reset password" />
      <CardContent>
        <ul className={styles.errorsList}>
          {errors.map(i => (
            <li className={styles.error} key={i}>
              {i}
            </li>
          ))}
        </ul>
        <div className={styles.wrapper}>
          <TextField
            className={styles.input}
            error={false}
            label={intl.formatMessage({
              id: "OrUrql",
              defaultMessage: "Password",
              description: "Password",
            })}
            name="password"
            value={values.password}
            onChange={e => {
              setValues({ ...values, ...{ password: e.target.value } });
              setIsDirty({ ...isDirty, ...{ password: true } });
            }}
            onFocus={setIsDirty.bind(null, {
              ...isDirty,
              ...{ password: true },
            })}
          />
          <TextField
            className={styles.input}
            error={false}
            label={intl.formatMessage({
              id: "wROF58",
              defaultMessage: "Repeat password",
              description: "Repeat password",
            })}
            name="repeatPassword"
            value={values.repeatPassword}
            onChange={e => {
              setValues({ ...values, ...{ repeatPassword: e.target.value } });
              setIsDirty({ ...isDirty, ...{ repeatPassword: true } });
            }}
            onFocus={setIsDirty.bind(null, {
              ...isDirty,
              ...{ repeatPassword: true },
            })}
          />
          <Button
            className={styles.button}
            disabled={
              !(isDirty.password || isDirty.repeatPassword)
                ? true
                : errors.length !== 0
            }
            onClick={onSubmit}
            variant="outlined"
          >
            Change
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

CustomerChangePassword.displayName = "CustomerChangePassword";
export default CustomerChangePassword;
