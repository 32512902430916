import CardTitle from "@dashboard/components/CardTitle";
import { DateTime } from "@dashboard/components/Date";
import FormSpacer from "@dashboard/components/FormSpacer";
import { Card, CardContent, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";

interface InfoProps {
  createdAt: string;
  updatedAt: string;
}

export const Info: React.FC<InfoProps> = ({ createdAt, updatedAt }) => (
  <Card>
    <CardTitle title="Summary" />
    <CardContent>
      <Typography variant="caption">Created</Typography>
      <Typography>
        {createdAt ? <DateTime date={createdAt} plain /> : <Skeleton />}
      </Typography>
      <FormSpacer />
      <Typography variant="caption">Updated</Typography>
      <Typography>
        {updatedAt ? <DateTime date={updatedAt} plain /> : <Skeleton />}
      </Typography>
    </CardContent>
  </Card>
);

Info.displayName = "Info";
