import CardTitle from "@dashboard/components/CardTitle";
import Grid from "@dashboard/components/Grid";
import SingleSelectField, {
  Choices,
} from "@dashboard/components/SingleSelectField";
import { AccountErrorFragment } from "@dashboard/graphql";
import { getFormErrors } from "@dashboard/utils/errors";
import { Card, CardContent, TextField } from "@material-ui/core";
import React from "react";

const flowMilesStatusChoices: Choices = [
  {
    value: "BRONZE",
    label: "Bronze",
  },
  {
    value: "SILVER",
    label: "Silver",
  },
  {
    value: "GOLD",
    label: "Gold",
  },
  {
    value: "PLATINUM",
    label: "Platinum",
  },
];

interface CustomerFlowmilesProps {
  data: {
    flowMiles: string;
    flowMileStatus: string;
  };
  disabled: boolean;
  errors: AccountErrorFragment[];
  onChange: (event: React.ChangeEvent<any>) => void;
}

const CustomerFlowmiles: React.FC<CustomerFlowmilesProps> = ({
  data,
  errors,
  disabled,
  onChange,
}) => {
  const formErrors = getFormErrors(["flowMiles"], errors);

  return (
    <Card>
      <CardTitle title="FlowMiles" />
      <CardContent>
        <Grid variant="uniform">
          <TextField
            disabled={disabled}
            error={!!formErrors.flowMiles}
            fullWidth
            name="flowMiles"
            type="number"
            value={data.flowMiles}
            onChange={onChange}
            label="Number"
            inputProps={{
              spellCheck: false,
            }}
          />
          <SingleSelectField
            value={data.flowMileStatus}
            choices={flowMilesStatusChoices}
            name="flowMileStatus"
            label="Status"
            onChange={onChange}
          />
        </Grid>
      </CardContent>
    </Card>
  );
};

CustomerFlowmiles.displayName = "CustomerFlowmiles";
export default CustomerFlowmiles;
