import FormSpacer from "@dashboard/components/FormSpacer";
import Grid from "@dashboard/components/Grid";
import { FormChange } from "@dashboard/hooks/useForm";
import { getFormErrors } from "@dashboard/utils/errors";
import { TextField } from "@material-ui/core";
import React from "react";
import { useIntl } from "react-intl";

export interface FormData {
  modalName: string;
  serialNumberPrefix: string;
  requiredNumbers: string;
  bluetoothProtocol: string;
  sku: string;
  manufacturerNumber: string;
  assemblyBatch: string;
}

export interface SerialNumberFormProps {
  data: FormData;
  onChange: FormChange;
  errors: any;
}

export const SerialNumberForm: React.FC<SerialNumberFormProps> = ({
  data,
  onChange,
  errors,
}) => {
  const intl = useIntl();

  const formErrors = getFormErrors<keyof FormData, any>(
    [
      "bluetoothProtocol",
      "modalName",
      "requiredNumbers",
      "serialNumberPrefix",
      "sku",
    ],
    errors,
  );

  return (
    <>
      <Grid variant="uniform">
        <TextField
          error={!!formErrors.modalName}
          helperText={
            !!formErrors.modalName
              ? "Model name is required"
              : "e.g. SO4 PRO 2nd Gen, SO ONE Pro grey"
          }
          label={intl.formatMessage({
            id: "yJhZiZ",
            defaultMessage: "Model name",
            description: "Model name",
          })}
          name="modalName"
          value={data.modalName}
          onChange={onChange}
        />
        <TextField
          error={!!formErrors.serialNumberPrefix}
          label={intl.formatMessage({
            id: "gnI0Xw",
            defaultMessage: "Serial number prefix",
            description: "Serial number prefix",
          })}
          helperText={
            !!formErrors.serialNumberPrefix
              ? "Serial number prefix is required"
              : "e.g. SFSO61907, SFSO312209"
          }
          name="serialNumberPrefix"
          value={data.serialNumberPrefix}
          onChange={onChange}
        />
        <TextField
          error={!!formErrors.sku}
          helperText={
            !!formErrors.sku
              ? "SKU is required"
              : "e.g. 300.330.01, 300.330.0123"
          }
          label={intl.formatMessage({
            id: "YE2t1s",
            defaultMessage: "SKU",
            description: "SKU",
          })}
          name="sku"
          value={data.sku}
          onChange={onChange}
        />
        <TextField
          error={!!formErrors.requiredNumbers}
          helperText={
            !!formErrors.requiredNumbers
              ? formErrors.requiredNumbers.code === "REQUIRED"
                ? "Required numbers are required"
                : "Required numbers must be more than 0"
              : ""
          }
          fullWidth
          type="number"
          label={intl.formatMessage({
            id: "Ubb+Wm",
            defaultMessage: "Required numbers",
            description: "Required numbers",
          })}
          name="requiredNumbers"
          value={data.requiredNumbers}
          onChange={onChange}
        />
        <TextField
          error={!!formErrors.manufacturerNumber}
          helperText={
            !!formErrors.manufacturerNumber
              ? "Manufacturer number is required"
              : "e.g. 300.534.02PGWO100"
          }
          label={intl.formatMessage({
            id: "JMNOR6",
            defaultMessage: "Manufacturer number",
            description: "Manufacturer number",
          })}
          name="manufacturerNumber"
          value={data.manufacturerNumber}
          onChange={onChange}
        />
        <TextField
          error={!!formErrors.assemblyBatch}
          helperText={
            !!formErrors.assemblyBatch
              ? "Assembly batch is required"
              : "e.g T15, SY-YT-003"
          }
          fullWidth
          label={intl.formatMessage({
            id: "qVL8js",
            defaultMessage: "Assembly batch",
            description: "Assembly batch",
          })}
          name="assemblyBatch"
          value={data.assemblyBatch}
          onChange={onChange}
        />
      </Grid>
      <FormSpacer />
      <TextField
        error={!!formErrors.bluetoothProtocol}
        helperText={
          !!formErrors.bluetoothProtocol
            ? "Bluetooth protocol is required"
            : "e.g. SO4Tv5.2"
        }
        fullWidth
        label={intl.formatMessage({
          id: "fnk1ez",
          defaultMessage: "Bluetooth protocol",
          description: "Bluetooth protocol",
        })}
        name="bluetoothProtocol"
        value={data.bluetoothProtocol}
        onChange={onChange}
      />
    </>
  );
};

SerialNumberForm.displayName = "SerialNumberForm";
export default SerialNumberForm;
