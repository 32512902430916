import CardTitle from "@dashboard/components/CardTitle";
import { DateTime } from "@dashboard/components/Date";
import { countries } from "@dashboard/fixtures";
import { CustomerDetailsQuery } from "@dashboard/graphql";
import { Card, CardContent, makeStyles } from "@material-ui/core";
import React, { useMemo } from "react";

const useStyles = makeStyles(
  () => ({
    card: {
      maxWidth: "70%",
    },
    spacer: {
      flexGrow: 1,
      borderBottom: "1px dotted rgba(0,0,0,.2)",
      position: "relative",
      marginLeft: 5,
      marginRight: 5,
    },
    line: {
      display: "flex",
      marginTop: 4,
      fontSize: 14,
      alignItems: "center",
    },
    name: {},
    value: {},
    values: {
      textAlign: "right",
    },
    list: {
      marginTop: -8,
      marginBottom: 0,
    },
  }),
  { name: "CustomerAdditionalInfo" },
);

const emptyValue = "No data";

interface CustomerAdditionalInfoProps {
  customer: CustomerDetailsQuery["user"];
}

export const CustomerAdditionalInfo: React.FC<CustomerAdditionalInfoProps> = ({
  customer,
}) => {
  const styles = useStyles();

  const showRolesVariant = useMemo(() => {
    if (!customer?.userRoles) {
      return null;
    } else {
      const fullLine = customer.userRoles.join(",");

      if (fullLine.length > 50) {
        return customer.userRoles;
      } else {
        return fullLine;
      }
    }
  }, [customer]);

  const country = useMemo(() => {
    if (!customer) {
      return "";
    }

    return (
      countries.find(i => i.code === customer.geolocationCountry)?.name ||
      emptyValue
    );
  }, [customer]);

  return (
    <Card>
      <CardTitle title="Additional Information" />
      <CardContent>
        <ul className={styles.list}>
          <li className={styles.line}>
            <span className={styles.name}>Account ID</span>
            <span className={styles.spacer}></span>
            <span className={styles.value}>{customer?.id || emptyValue}</span>
          </li>
          <li className={styles.line}>
            <span className={styles.name}>Account roles</span>
            <span className={styles.spacer}></span>
            {showRolesVariant === null && (
              <span className={styles.value}>{emptyValue}</span>
            )}
            {!Array.isArray(showRolesVariant) && (
              <span className={styles.value}>{showRolesVariant}</span>
            )}
            {Array.isArray(showRolesVariant) && (
              <ul className={styles.values}>
                {showRolesVariant.map(i => (
                  <li key={i}>{i}</li>
                ))}
              </ul>
            )}
          </li>
          <li className={styles.line}>
            <span className={styles.name}>Creation date</span>
            <span className={styles.spacer}></span>
            <span className={styles.value}>
              {customer?.dateJoined ? (
                <DateTime date={customer.dateJoined} plain />
              ) : (
                emptyValue
              )}
            </span>
          </li>
          <li className={styles.line}>
            <span className={styles.name}>Phone confirmed at</span>
            <span className={styles.spacer}></span>
            <span className={styles.value}>
              {customer?.phoneConfirmedAt ? (
                <DateTime date={customer.phoneConfirmedAt} plain />
              ) : (
                emptyValue
              )}
            </span>
          </li>
          <li className={styles.line}>
            <span className={styles.name}>Country mobile geo location</span>
            <span className={styles.spacer}></span>
            <span className={styles.value}>{country}</span>
          </li>
          <li className={styles.line}>
            <span className={styles.name}>
              Country mobile geo location confirmed at
            </span>
            <span className={styles.spacer}></span>
            <span className={styles.value}>
              {customer?.geolocationCountryConfirmedAt ? (
                <DateTime date={customer.geolocationCountryConfirmedAt} plain />
              ) : (
                emptyValue
              )}
            </span>
          </li>
          <li className={styles.line}>
            <span className={styles.name}>Application language</span>
            <span className={styles.spacer}></span>
            <span className={styles.value}>Not available, in development</span>
          </li>
          <li className={styles.line}>
            <span className={styles.name}>Application registration date</span>
            <span className={styles.spacer}></span>
            <span className={styles.value}>Not available, in development</span>
          </li>
          <li className={styles.line}>
            <span className={styles.name}>OTA number</span>
            <span className={styles.spacer}></span>
            <span className={styles.value}>Not available, in development</span>
          </li>
          <li className={styles.line}>
            <span className={styles.name}>App version</span>
            <span className={styles.spacer}></span>
            <span className={styles.value}>
              {customer?.appVersion || emptyValue}
            </span>
          </li>
          <li className={styles.line}>
            <span className={styles.name}>App device id</span>
            <span className={styles.spacer}></span>
            <span className={styles.value}>
              {customer?.appDeviceId || emptyValue}
            </span>
          </li>
          <li className={styles.line}>
            <span className={styles.name}>App platform</span>
            <span className={styles.spacer}></span>
            <span className={styles.value}>
              {customer?.appPlatform || emptyValue}
            </span>
          </li>
          <li className={styles.line}>
            <span className={styles.name}>App build number</span>
            <span className={styles.spacer}></span>
            <span className={styles.value}>
              {customer?.appBuildNumber || emptyValue}
            </span>
          </li>
          <li className={styles.line}>
            <span className={styles.name}>App device model</span>
            <span className={styles.spacer}></span>
            <span className={styles.value}>
              {customer?.appDeviceModel || emptyValue}
            </span>
          </li>
          <li className={styles.line}>
            <span className={styles.name}>App request source</span>
            <span className={styles.spacer}></span>
            <span className={styles.value}>
              {customer?.appRequestSource || emptyValue}
            </span>
          </li>
        </ul>
      </CardContent>
    </Card>
  );
};

CustomerAdditionalInfo.displayName = "CustomerAdditionalInfo";
export default CustomerAdditionalInfo;
