import { ApolloError, useMutation } from "@apollo/client";
import CardTitle from "@dashboard/components/CardTitle";
import SingleSelectField, {
  Choices,
} from "@dashboard/components/SingleSelectField";
import useNotifier from "@dashboard/hooks/useNotifier";
import { uploadDistributor } from "@dashboard/serialNumber/mutations";
import { UploadDistributorResponseType } from "@dashboard/serialNumber/types";
import { Button, Card, CardContent, Link, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";

const useStyles = makeStyles(
  () => ({
    root: {
      marginTop: 30,
    },
    uploadArea: {
      cursor: "pointer",
      border: "1px dashed #ccc",
      height: "48px",
      borderRadius: "4px",
      marginRight: "30px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
    },
    select: {
      width: "25%",
    },
    card: {
      display: "flex",
      flexDirection: "column",
    },
    uploadWrapper: {
      width: "25%",
    },
    wrapper: {
      display: "flex",
      marginBottom: "12px",
    },
    button: {
      maxWidth: "100px",
    },
    input: {
      display: "none",
    },
    error: {
      fontSize: 12,
      color: "red",
      marginLeft: 3,
      marginTop: 4,
    },
    template: {
      marginTop: "25px",
    },
    templateList: {
      marginTop: 10,
      lineHeight: "20px",
    },
    label: {
      marginRight: 10,
      fontSize: 14,
    },
    download: {
      marginRight: 7,
    },
  }),
  { name: "UploadDistributorForm" },
);

const distributors: Choices = [
  {
    value: "ALLTRON",
    label: "Distributor: Alltron",
  },
  {
    value: "KOMSA",
    label: "Distributor: Komsa",
  },
  {
    value: "MANUFACTURER",
    label: "Manufacturer data",
  },
  {
    value: "XANO",
    label: "Xano Import",
  },
];

export const UploadDistributorForm = () => {
  const notify = useNotifier();

  const inputRef = useRef<HTMLInputElement | null>(null);

  const [isValid, setIsValid] = useState(false);
  const [selectedDistributor, setSelectedDistributor] = useState("");
  const [selectedFile, setSelectedFile] = useState<null | File>(null);
  const [fileError, setFileError] = useState("");

  const styles = useStyles();

  useEffect(() => {
    setIsValid(!!selectedDistributor && !!selectedFile);
  }, [selectedDistributor, selectedFile]);

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      if (file.type !== "text/csv") {
        setFileError("Please, select correct file, only .csv available");
      } else {
        setSelectedFile(file);
        setFileError("");
      }
    }
  };

  const onUpload = async () => {
    const { data } = await uploadFile({
      variables: {
        file: selectedFile,
        distributor: selectedDistributor,
      },
    });

    if (data && data.scooterCsvUpload && !data.scooterCsvUpload.errors.length) {
      inputRef.current.value = "";
      setFileError("");
      setSelectedDistributor("");
      setSelectedFile(null);
      notify({
        status: "success",
        title: "Success",
        text: "File successfully loaded",
      });
    }
  };

  const onError = (error: ApolloError) => {
    setFileError(`In file expect field: ${error.message}`);
    notify({ status: "error", title: "Error", text: "Please try again later" });
  };

  const [uploadFile] = useMutation<UploadDistributorResponseType>(
    uploadDistributor,
    { onError },
  );

  return (
    <Card className={styles.root}>
      <CardTitle title="Upload additional serial-number information" />
      <CardContent className={styles.card}>
        <div className={styles.wrapper}>
          <div className={styles.uploadWrapper}>
            <div
              className={styles.uploadArea}
              onClick={() => inputRef.current?.click()}
            >
              {selectedFile === null
                ? "Select file, available only .csv"
                : selectedFile.name}
              <input
                className={styles.input}
                ref={inputRef}
                type="file"
                accept=".csv"
                onChange={onFileChange}
                multiple={false}
              />
            </div>
            {fileError && <div className={styles.error}>{fileError}</div>}
          </div>
          <SingleSelectField
            className={styles.select}
            value={selectedDistributor}
            choices={distributors}
            label="Select file upload type"
            onChange={e => setSelectedDistributor(e.target.value as string)}
          />
        </div>
        <Button
          className={styles.button}
          disabled={!isValid}
          onClick={onUpload}
          variant="outlined"
        >
          Upload file
        </Button>
        <div className={styles.template}>
          <div className={styles.label}>Get upload-templates:</div>
          <ul className={styles.templateList}>
            <li>
              <Link
                className={styles.download}
                href="https://storage.googleapis.com/soflow-dev/dashboard/alltron.csv"
                download
              >
                Distributor: Alltron (.csv)
              </Link>
            </li>
            <li>
              <Link
                className={styles.download}
                href="https://storage.googleapis.com/soflow-dev/dashboard/komsa.csv"
                download
              >
                Distributor: Komsa (.csv)
              </Link>
            </li>
            <li>
              <Link
                className={styles.download}
                href="https://storage.googleapis.com/soflow-dev/dashboard/manufacturer-data.csv"
                download
              >
                Manufacturer data (.csv)
              </Link>
            </li>
          </ul>
        </div>
      </CardContent>
    </Card>
  );
};

UploadDistributorForm.displayName = "UploadDistributorForm";
export default UploadDistributorForm;
