import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { SketchPicker } from "react-color";

const useStyles = makeStyles(
  () => ({
    root: {
      position: "relative",
    },
    value: {
      width: "36px",
      height: "14px",
      borderRadius: "2px",
    },
    wrapper: {
      display: "flex",
      alignItems: "center",
    },
    color: {
      padding: "5px",
      background: "#fff",
      borderRadius: "1px",
      boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
      cursor: "pointer",
    },
    label: {
      marginRight: "10px",
    },
    picker: {
      position: "absolute",
      left: 0,
      top: "30px",
      zIndex: 100,
    },
  }),
  { name: "SketchColorPicker" },
);

interface SketchColorPickerProps {
  className?: string;
  label?: string;
  value: string;
  onChange: (color: string) => void;
}

export const SketchColorPicker: React.FC<SketchColorPickerProps> = ({
  className,
  onChange,
  value,
  label,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef<HTMLDivElement>();

  const styles = useStyles();

  const onDocumentClick = ({ target }: any) => {
    if (ref.current && !ref.current.contains(target)) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", onDocumentClick);
    return () => {
      document.removeEventListener("mousedown", onDocumentClick);
    };
  }, []);

  return (
    <div ref={ref} className={clsx(styles.root, className)}>
      <div
        className={styles.wrapper}
        onClick={setIsVisible.bind(null, !isVisible)}
      >
        <span className={styles.label}>{label}</span>
        <div className={styles.color}>
          <div className={styles.value} style={{ backgroundColor: value }} />
        </div>
      </div>
      {isVisible && (
        <SketchPicker
          className={styles.picker}
          color={value}
          onChange={({ hex }) => onChange(hex)}
        />
      )}
    </div>
  );
};

SketchColorPicker.displayName = "SketchColorPicker";
export default SketchColorPicker;
