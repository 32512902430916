import { FilterElement } from "@dashboard/components/Filter";
import {
  CustomerFilterKeys,
  CustomerListFilterOpts,
} from "@dashboard/customers/components/CustomerListPage";
import { CustomerFilterInput } from "@dashboard/graphql";
import { maybe } from "@dashboard/misc";

import {
  createFilterTabUtils,
  createFilterUtils,
  getGteLteVariables,
  getMinMaxQueryParam,
} from "../../../utils/filters";
import {
  CustomerListUrlFilters,
  CustomerListUrlFiltersEnum,
  CustomerListUrlQueryParams,
} from "../../urls";

export const CUSTOMER_FILTERS_KEY = "customerFilters";

export function getFilterOpts(
  params: CustomerListUrlFilters,
): CustomerListFilterOpts {
  return {
    lastLogin: {
      active: maybe(
        () =>
          [params.lastLoginFrom, params.lastLoginTo].some(
            field => field !== undefined,
          ),
        false,
      ),
      value: {
        max: maybe(() => params.lastLoginTo, ""),
        min: maybe(() => params.lastLoginFrom, ""),
      },
    },
    joined: {
      active: maybe(
        () =>
          [params.joinedFrom, params.joinedTo].some(
            field => field !== undefined,
          ),
        false,
      ),
      value: {
        max: maybe(() => params.joinedTo, ""),
        min: maybe(() => params.joinedFrom, ""),
      },
    },
    numberOfOrders: {
      active: maybe(
        () =>
          [params.numberOfOrdersFrom, params.numberOfOrdersTo].some(
            field => field !== undefined,
          ),
        false,
      ),
      value: {
        max: maybe(() => params.numberOfOrdersTo, ""),
        min: maybe(() => params.numberOfOrdersFrom, ""),
      },
    },
    availableFlowMiles: {
      active: maybe(
        () =>
          [params.availableFlowMilesFrom, params.availableFlowMilesTo].some(
            field => field !== undefined,
          ),
        false,
      ),
      value: {
        max: maybe(() => params.availableFlowMilesTo, ""),
        min: maybe(() => params.availableFlowMilesFrom, ""),
      },
    },
    birthdate: {
      active: maybe(
        () =>
          [params.birthdateFrom, params.birthdateTo].some(
            field => field !== undefined,
          ),
        false,
      ),
      value: {
        max: maybe(() => params.birthdateTo, ""),
        min: maybe(() => params.birthdateFrom, ""),
      },
    },
  };
}

export function getFilterVariables(
  params: CustomerListUrlFilters,
): CustomerFilterInput {
  return {
    dateJoined: getGteLteVariables({
      gte: params.joinedFrom,
      lte: params.joinedTo,
    }),
    lastLogin: getGteLteVariables({
      gte: params.lastLoginFrom,
      lte: params.lastLoginTo,
    }),
    birthDate: getGteLteVariables({
      gte: params.birthdateFrom,
      lte: params.birthdateTo,
    }),
    numberOfOrders: getGteLteVariables({
      gte: parseInt(params.numberOfOrdersFrom, 10),
      lte: parseInt(params.numberOfOrdersTo, 10),
    }),
    flowMiles: getGteLteVariables({
      gte: parseInt(params.availableFlowMilesFrom, 10),
      lte: parseInt(params.availableFlowMilesTo, 10),
    }),
    search: params.query,
  };
}

export function getFilterQueryParam(
  filter: FilterElement<CustomerFilterKeys>,
): CustomerListUrlFilters {
  const { name } = filter;

  switch (name) {
    case CustomerFilterKeys.joined:
      return getMinMaxQueryParam(
        filter,
        CustomerListUrlFiltersEnum.joinedFrom,
        CustomerListUrlFiltersEnum.joinedTo,
      );

    case CustomerFilterKeys.birthdate:
      return getMinMaxQueryParam(
        filter,
        CustomerListUrlFiltersEnum.birthdateFrom,
        CustomerListUrlFiltersEnum.birthdateTo,
      );

    case CustomerFilterKeys.availableFlowMiles:
      return getMinMaxQueryParam(
        filter,
        CustomerListUrlFiltersEnum.availableFlowMilesFrom,
        CustomerListUrlFiltersEnum.availableFlowMilesTo,
      );

    case CustomerFilterKeys.lastLogin:
      return getMinMaxQueryParam(
        filter,
        CustomerListUrlFiltersEnum.lastLoginFrom,
        CustomerListUrlFiltersEnum.lastLoginTo,
      );

    case CustomerFilterKeys.numberOfOrders:
      return getMinMaxQueryParam(
        filter,
        CustomerListUrlFiltersEnum.numberOfOrdersFrom,
        CustomerListUrlFiltersEnum.numberOfOrdersTo,
      );
  }
}

export const { deleteFilterTab, getFilterTabs, saveFilterTab } =
  createFilterTabUtils<CustomerListUrlFilters>(CUSTOMER_FILTERS_KEY);

export const { areFiltersApplied, getActiveFilters, getFiltersCurrentTab } =
  createFilterUtils<CustomerListUrlQueryParams, CustomerListUrlFilters>(
    CustomerListUrlFiltersEnum,
  );
