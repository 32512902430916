import { TopNav } from "@dashboard/components/AppLayout";
import { DetailPageLayout } from "@dashboard/components/Layouts";
import { useFlowMilePurchaseDetailQuery } from "@dashboard/graphql";
import { Card, CardContent } from "@material-ui/core";
import React from "react";
import { useIntl } from "react-intl";
import { RouteComponentProps } from "react-router";

import { flowmilesPurchasesPath } from "../urls";

interface FlowmilesPurchasesDetailsRouteParams {
  id: string;
}

export const FlowmilesPurchasesDetails: React.FC<
  RouteComponentProps<FlowmilesPurchasesDetailsRouteParams>
> = ({ match }) => {
  const intl = useIntl();

  const { data } = useFlowMilePurchaseDetailQuery({
    variables: { id: match.params.id },
  });

  if (!data) {
    return null;
  }

  return (
    <DetailPageLayout gridTemplateColumns={1}>
      <TopNav
        href={flowmilesPurchasesPath}
        // eslint-disable-next-line formatjs/enforce-id
        title={intl.formatMessage({
          id: "tTRuy8",
          defaultMessage: data.flowMilePurchase.voucherCode,
          description: "page header",
        })}
      />
      <DetailPageLayout.Content>
        <Card>
          <CardContent>Page in development</CardContent>
        </Card>
      </DetailPageLayout.Content>
    </DetailPageLayout>
  );
};
