import ActionDialog from "@dashboard/components/ActionDialog";
import { TopNav } from "@dashboard/components/AppLayout";
import CardTitle from "@dashboard/components/CardTitle";
import { DateTime } from "@dashboard/components/Date";
import { DetailPageLayout } from "@dashboard/components/Layouts";
import Link from "@dashboard/components/Link";
import NotFoundPage from "@dashboard/components/NotFoundPage";
import TableRowLink from "@dashboard/components/TableRowLink";
import {
  LockResetMutation,
  useLockDetailsQuery,
  useLockResetMutation,
} from "@dashboard/graphql";
import useNavigator from "@dashboard/hooks/useNavigator";
import useNotifier from "@dashboard/hooks/useNotifier";
import { commonMessages } from "@dashboard/intl";
import createDialogActionHandlers from "@dashboard/utils/handlers/dialogActionHandlers";
import {
  Button,
  Card,
  CardContent,
  DialogContentText,
  makeStyles,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { ResponsiveTable } from "@saleor/macaw-ui";
import { parse as parseQs } from "qs";
import React, { useEffect, useState } from "react";
import { GrPowerReset } from "react-icons/gr";
import { FormattedMessage, useIntl } from "react-intl";
import { RouteComponentProps } from "react-router";

import { LockOwnerType } from "../types";
import {
  lockListPath,
  lockUrl,
  LockUrlDialog,
  LockUrlQueryParams,
} from "../urls";

const useStyles = makeStyles(
  () => ({
    card: {},
    spacer: {
      flexGrow: 1,
      borderBottom: "1px dotted rgba(0,0,0,.2)",
      position: "relative",
      bottom: 9,
      marginLeft: 5,
      marginRight: 5,
    },
    line: {
      display: "flex",
      marginTop: 4,
      fontSize: 14,
    },
    name: {},
    value: {},
    list: {
      marginTop: -8,
      marginBottom: 0,
    },
    title: {
      color: "lightseagreen",
    },
    table: {},
    button: {
      width: "100%",
      marginBlock: "1rem",
    },
  }),
  { name: "LockDetailView" },
);

const emptyValue = "-";

interface LockDetailsRouteParams {
  id: string;
}

export const LockDetailsView: React.FC<
  RouteComponentProps<LockDetailsRouteParams>
> = ({ match, location }) => {
  const intl = useIntl();
  const styles = useStyles();
  const notify = useNotifier();
  const navigate = useNavigator();
  const params = parseQs(location.search.substr(1));
  const [owner, setOwner] = useState<null | LockOwnerType>(null);

  const { data, loading, refetch } = useLockDetailsQuery({
    variables: { id: match.params.id },
  });

  const handleLockReset = (data: LockResetMutation) => {
    if (data.lockReset.errors.length === 0) {
      closeModal();
      notify({
        status: "success",
        text: intl.formatMessage({
          id: "fIPMXV",
          defaultMessage: "Lock reset",
        }),
      });
      refetch();
    }
  };

  const [resetLock, resetLockResult] = useLockResetMutation({
    onCompleted: handleLockReset,
  });

  const [openModal, closeModal] = createDialogActionHandlers<
    LockUrlDialog,
    LockUrlQueryParams
  >(navigate, params => lockUrl(match.params.id, params), params);

  useEffect(() => {
    if (data) {
      setOwner(data.lock?.owner);
    }
  }, [data]);

  if (loading) {
    return null;
  }

  if (!(data && data.lock)) {
    return <NotFoundPage backHref={lockListPath} />;
  }

  return (
    <DetailPageLayout>
      <TopNav
        href={lockListPath}
        title={<>{data.lock.serialNumber}</>}
      ></TopNav>
      <DetailPageLayout.RightSidebar>
        <Card>
          <CardTitle title={intl.formatMessage(commonMessages.summary)} />
          <CardContent>
            <Typography variant="caption">
              <FormattedMessage
                id="occvog"
                defaultMessage="Factory Reset Lock"
                description="Factory reset lock"
              />
            </Typography>
            <Button
              onClick={() => openModal("delete")}
              variant="contained"
              color="primary"
              className={styles.button}
              data-test-id="reset-owner"
            >
              <GrPowerReset style={{ marginRight: 8 }} />
              <FormattedMessage
                id="IYOra8"
                defaultMessage="Reset Lock"
                description="button"
              />
            </Button>
            <Typography>
              This action will reset all owner and user details.
            </Typography>
          </CardContent>
        </Card>
      </DetailPageLayout.RightSidebar>
      <DetailPageLayout.Content>
        <Card className={styles.card}>
          <CardTitle title="Lock details" className={styles.title} />
          <CardContent>
            <ul className={styles.list}>
              <li className={styles.line}>
                <span className={styles.name}>Serial number</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.lock.serialNumber || emptyValue}
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>MAC address</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.lock.mac || emptyValue}
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>Status</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.lock.status.toUpperCase() || emptyValue}
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>Active</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.lock.isActive ? "Active" : "Inactive" || emptyValue}
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>Firmware version</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.lock.version || emptyValue}
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>Password Decimal</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.lock.passwordDec || emptyValue}
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>Key Decimal</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.lock.keyDec || emptyValue}
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>Password Hexadecimal</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.lock.passwordHex || emptyValue}
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>Key Hexadecimal</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.lock.keyHex || emptyValue}
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>Warehoused date</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.lock.warehousedAt ? (
                    <DateTime date={data.lock.warehousedAt} plain />
                  ) : (
                    emptyValue
                  )}
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>First Registration</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.lock.firstRegistration ? (
                    <DateTime date={data.lock.firstRegistration} plain />
                  ) : (
                    emptyValue
                  )}
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>Last login</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.lock.lastLogin ? (
                    <DateTime date={data.lock.lastLogin} plain />
                  ) : (
                    emptyValue
                  )}
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>Last owner change</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  {data.lock.lastOwnerChange ? (
                    <DateTime date={data.lock.lastOwnerChange} plain />
                  ) : (
                    emptyValue
                  )}
                </span>
              </li>
            </ul>
          </CardContent>
        </Card>
        <Card className={styles.card}>
          <CardTitle title="Owner details" className={styles.title} />
          <CardContent>
            <ul className={styles.list}>
              <li className={styles.line}>
                <span className={styles.name}>Email</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  <b>
                    {owner?.user.email ? (
                      <Link href={`/customers/${owner.user.id}`}>
                        {owner.user.email}
                      </Link>
                    ) : (
                      emptyValue
                    )}
                  </b>
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>Last login</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  <b>
                    {owner?.user.lastLogin ? (
                      <DateTime date={owner?.user.lastLogin} plain />
                    ) : (
                      emptyValue
                    )}
                  </b>
                </span>
              </li>
              <li className={styles.line}>
                <span className={styles.name}>Registered at</span>
                <span className={styles.spacer}></span>
                <span className={styles.value}>
                  <b>
                    {owner?.createdAt ? (
                      <DateTime date={owner?.createdAt} plain />
                    ) : (
                      emptyValue
                    )}
                  </b>
                </span>
              </li>
            </ul>
          </CardContent>
        </Card>
        <Card>
          <CardTitle title="Other users" />
          <CardContent>
            <ResponsiveTable className={styles.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell>Added at</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.lock.users && data?.lock.users.length > 0 && (
                  <>
                    {data.lock.users.map(({ user, createdAt }) => (
                      <TableRowLink key={user.id}>
                        <TableCell>
                          <Link href={`/customers/${user.id}`}>
                            {user.email}
                          </Link>
                        </TableCell>
                        <TableCell>
                          <DateTime date={createdAt} plain />
                        </TableCell>
                      </TableRowLink>
                    ))}
                  </>
                )}
                {(!data?.lock.users || data.lock.users.length === 0) && (
                  <TableRowLink hover={false}>
                    <TableCell colSpan={4}>
                      No users added to the lock.
                    </TableCell>
                  </TableRowLink>
                )}
              </TableBody>
            </ResponsiveTable>
          </CardContent>
        </Card>
        <Card>
          <CardTitle title="Fingerprints" />
          <CardContent>
            <ResponsiveTable className={styles.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Nickname</TableCell>
                  <TableCell>Pointer</TableCell>
                  <TableCell>Created at</TableCell>
                  <TableCell>Updated at</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.lock.fingerprints &&
                  data?.lock.fingerprints.length > 0 && (
                    <>
                      {data.lock.fingerprints.map(
                        ({ nickname, pointer, createdAt, updatedAt }) => (
                          <TableRowLink key={pointer}>
                            <TableCell>{nickname}</TableCell>
                            <TableCell>{pointer}</TableCell>
                            <TableCell>
                              <DateTime date={createdAt} plain />
                            </TableCell>
                            <TableCell>
                              <DateTime date={updatedAt} plain />
                            </TableCell>
                          </TableRowLink>
                        ),
                      )}
                    </>
                  )}
                {(!data?.lock.fingerprints ||
                  data.lock.fingerprints.length === 0) && (
                  <TableRowLink hover={false}>
                    <TableCell colSpan={4}>
                      No fingerprints added to lock.
                    </TableCell>
                  </TableRowLink>
                )}
              </TableBody>
            </ResponsiveTable>
          </CardContent>
        </Card>
        <Card>
          <CardTitle title="Owner History" />
          <CardContent>
            <ResponsiveTable className={styles.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell>Owned at</TableCell>
                  <TableCell>Removed at</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.lock.pastOwners && data?.lock.pastOwners.length > 0 && (
                  <>
                    {data.lock.pastOwners.map(
                      ({ user, createdAt, removedAt }) => (
                        <TableRowLink key={user.id}>
                          <TableCell>
                            <Link href={`/customers/${user.id}`}>
                              {user.email}
                            </Link>
                          </TableCell>
                          <TableCell>
                            <DateTime date={createdAt} plain />
                          </TableCell>
                          <TableCell>
                            <DateTime date={removedAt} plain />
                          </TableCell>
                        </TableRowLink>
                      ),
                    )}
                  </>
                )}
                {(!data?.lock.pastOwners ||
                  data.lock.pastOwners.length === 0) && (
                  <TableRowLink hover={false}>
                    <TableCell colSpan={4}>No owner history found.</TableCell>
                  </TableRowLink>
                )}
              </TableBody>
            </ResponsiveTable>
          </CardContent>
        </Card>
        <ActionDialog
          confirmButtonState={resetLockResult.status}
          onClose={closeModal}
          onConfirm={() => resetLock({ variables: { id: match.params.id } })}
          open={params.action === "delete"}
          title={intl.formatMessage({
            id: "Tt2WGk",
            defaultMessage: "Reset lock",
            description: "dialog title",
          })}
          variant="delete"
        >
          <DialogContentText>
            <FormattedMessage
              id="XLYs05"
              defaultMessage="Are you sure you want to reset {serialNumber}?"
              values={{
                serialNumber: <strong>{data.lock.serialNumber}</strong>,
              }}
            />
          </DialogContentText>
          <DialogContentText>
            <FormattedMessage
              id="EgrJdv"
              defaultMessage="Remember this will remove owner, all users, and all fingerprints."
            />
          </DialogContentText>
        </ActionDialog>
      </DetailPageLayout.Content>
    </DetailPageLayout>
  );
};
