import { useMutation } from "@apollo/client";
import { TopNav } from "@dashboard/components/AppLayout/TopNav";
import { Button } from "@dashboard/components/Button";
import Form from "@dashboard/components/Form";
import { DetailPageLayout } from "@dashboard/components/Layouts";
import { Card, CardContent } from "@material-ui/core";
import { makeStyles } from "@saleor/macaw-ui";
import { Box } from "@saleor/macaw-ui/next";
import React, { useState } from "react";

import { FormData, SerialNumberForm } from "./components/SerialNumberForm";
import { validateSerialNumberFormData } from "./components/SerialNumberForm/validation";
import UploadDistributorForm from "./components/UploadDistributorForm";
import { scooterGenerate } from "./mutations";

export const useStyles = makeStyles(
  theme => ({
    error: {
      marginBottom: theme.spacing(1),
      color: "#bd2b2b",
    },
    generate: {
      marginTop: 20,
    },
  }),
  { name: "SerialNumberPage" },
);

const initialData: FormData = {
  bluetoothProtocol: "",
  modalName: "",
  requiredNumbers: "",
  serialNumberPrefix: "",
  sku: "",
  assemblyBatch: "",
  manufacturerNumber: "",
};

export const SerialNumberPage: React.FC = () => {
  const classes = useStyles();

  const [createSerialNumber] = useMutation(scooterGenerate);
  const [fileBlob, setFileBlob] = useState<Blob | null>(null);
  const [linkParams, setLinkParams] = useState<null | URLSearchParams>(null);
  const [errors, setErrors] = useState(null);

  const [validationErrors, setValidationErrors] = useState([]);

  const handleSubmit = async (values: FormData): Promise<any> => {
    const errors = validateSerialNumberFormData(values);

    if (errors.length) {
      setValidationErrors(errors);
    } else {
      const { data } = await createSerialNumber({
        variables: {
          input: {
            sku: values.sku,
            serialNumberPrefix: values.serialNumberPrefix,
            bluetoothProtocol: values.bluetoothProtocol,
            scooterModel: values.modalName,
            batchSize: values.requiredNumbers,
            assemblyBatch: values.assemblyBatch,
            manufacturerNumber: values.manufacturerNumber,
          },
        },
      });

      if (data.scooterGenerate.downloadUrl) {
        setErrors(null);
        setLinkParams(
          new URLSearchParams(data.scooterGenerate.downloadUrl.split("?")[1]),
        );
        await getFile(data.scooterGenerate.downloadUrl);
      } else {
        setFileBlob(null);
        setLinkParams(null);
        setErrors(data.scooterGenerate.errors);
      }
    }
  };

  const getFile = async (url: string) => {
    const headers = new Headers();
    headers.append(
      "Authorization",
      `Bearer ${localStorage.getItem("_saleorRefreshToken")}`,
    );

    const result = await fetch(url, {
      method: "GET",
      headers,
    });

    setFileBlob(await result.blob());
  };

  const onDownload = () => {
    const objectUrl = window.URL.createObjectURL(fileBlob);
    const anchor = document.createElement("a");
    anchor.href = objectUrl;
    anchor.download = `Serial-numbers from [${linkParams.get(
      "start",
    )}] to [${linkParams.get("stop")}].csv`;
    anchor.click();
    window.URL.revokeObjectURL(objectUrl);
  };

  return (
    <DetailPageLayout gridTemplateColumns={1} withSavebar={false}>
      <TopNav title="Serial Number Management" />
      <DetailPageLayout.Content>
        <Box paddingX={3} __maxWidth={"100%"} margin="auto">
          <Form confirmLeave onSubmit={handleSubmit} initial={initialData}>
            {({ change, data, submit }) => (
              <>
                <Card>
                  <CardContent>
                    {errors && errors.length && (
                      <ul>
                        {errors.map(item => (
                          <li className={classes.error} key={item.message}>
                            {item.message}
                          </li>
                        ))}
                      </ul>
                    )}
                    <SerialNumberForm
                      data={data}
                      onChange={change}
                      errors={validationErrors}
                    />
                    {fileBlob && (
                      <CardContent>
                        {linkParams && (
                          <p>
                            Serial-numbers from [{linkParams.get("start")}] to [
                            {linkParams.get("stop")}] have been created.
                          </p>
                        )}
                        <Button onClick={onDownload}>Download .csv</Button>
                      </CardContent>
                    )}
                    <Button
                      className={classes.generate}
                      onClick={submit}
                      variant="primary"
                    >
                      Generate
                    </Button>
                  </CardContent>
                </Card>
                <UploadDistributorForm />
              </>
            )}
          </Form>
        </Box>
      </DetailPageLayout.Content>
    </DetailPageLayout>
  );
};
