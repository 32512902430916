import { makeStyles } from "@saleor/macaw-ui";

export const useStyles = makeStyles(
  {
    container: {
      display: "block",
    },
    optionLabel: {
      display: "block",
    },
    overflow: {
      overflowY: "visible",
    },
  },
  { name: "OrderCustomerChangeDialog" },
);
