import { gql } from "@apollo/client";

export const scooterGenerate = gql`
  mutation ScooterGenerate($input: ScooterGenerateInput) {
    scooterGenerate(input: $input) {
      downloadUrl
      errors {
        field
        message
        code
      }
    }
  }
`;

export const uploadDistributor = gql`
  mutation ScooterCsvUpload(
    $distributor: DistributorTypeEnum!
    $file: Upload!
  ) {
    scooterCsvUpload(file: $file, distributor: $distributor) {
      errors {
        field
        message
      }
    }
  }
`;
