import { TopNav } from "@dashboard/components/AppLayout";
import { DetailPageLayout } from "@dashboard/components/Layouts";
import { Card, CardContent } from "@material-ui/core";
import React from "react";
import { useIntl } from "react-intl";

import { flowmilesPurchasesPath } from "../urls";

export const FlowmilesPurchasesCreate = () => {
  const intl = useIntl();

  return (
    <DetailPageLayout gridTemplateColumns={1}>
      <TopNav
        href={flowmilesPurchasesPath}
        title={intl.formatMessage({
          id: "boWubh",
          defaultMessage: "Create FlowMiles purchases",
          description: "page header",
        })}
      />
      <DetailPageLayout.Content>
        <Card>
          <CardContent>Page in development</CardContent>
        </Card>
      </DetailPageLayout.Content>
    </DetailPageLayout>
  );
};
