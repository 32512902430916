import SingleSelectField from "@dashboard/components/SingleSelectField";
import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";

const useStyles = makeStyles(
  () => ({
    root: {
      display: "flex",
      alignItems: "center",
    },
    label: {
      paddingRight: "10px",
      opacity: ".7",
      fontSize: 13,
    },
  }),
  { name: "LanguageSwitcher" },
);

interface LanguageSwitcherProps {
  defaultValue?: string;
  onChange: (value: string) => void;
}

const languages = [
  {
    label: "English",
    value: "en",
  },
  {
    label: "German",
    value: "de",
  },
  {
    label: "French",
    value: "fr",
  },
  {
    label: "Italian",
    value: "it",
  },
  {
    label: "Spanish",
    value: "es",
  },
];

export const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({
  defaultValue = "",
  onChange,
}) => {
  const [] = useState();

  const styles = useStyles();

  return (
    <div className={styles.root}>
      <span className={styles.label}>Language:</span>
      <SingleSelectField
        testId="flowmiles-shop-language-switcher"
        choices={languages}
        value={defaultValue}
        onChange={event => onChange(event.target.value)}
      />
    </div>
  );
};

LanguageSwitcher.displayName = "LanguageSwitcher";
