import CardTitle from "@dashboard/components/CardTitle";
import { useScooterUpdatePasswordMutation } from "@dashboard/graphql";
import useNotifier from "@dashboard/hooks/useNotifier";
import {
  Button,
  Card,
  CardContent,
  makeStyles,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import useRouter from "use-react-router";

const useStyles = makeStyles(
  () => ({
    error: {
      color: "red",
    },
    errorsList: {
      marginTop: 0,
    },
    title: {
      color: "lightseagreen",
    },
    grid: {
      display: "grid",
      gap: "0.8rem",
    },
  }),
  { name: "ChangePassword" },
);

export const ChangePassword = () => {
  const notify = useNotifier();
  const { match } = useRouter<{ id: string }>();
  const styles = useStyles();
  const intl = useIntl();

  const [errors, setErrors] = useState<string[]>([]);

  const [values, setValues] = useState({ password: "", repeatPassword: "" });
  const [isDirty, setIsDirty] = useState({
    password: false,
    repeatPassword: false,
  });

  const [updatePassword] = useScooterUpdatePasswordMutation();

  useEffect(() => {
    const error = [];

    if (isDirty.password) {
      if (!values.password) {
        error.push("Password field is required");
      } else {
        if (values.password.length <= 4) {
          error.push("Password length must be more than 4 symbols");
        }
        /* if(values.password.length !== 6) {
          error.push('Password length should be 6 symbols');
        }
        if(!/^[A-Za-z0-9]*$/.test(values.password)) {
          error.push('Password can include only letters and numbers');
        } */
      }
    }

    if (!values.repeatPassword && isDirty.repeatPassword) {
      error.push("Repeat password field is required");
    }

    if (
      values.repeatPassword !== "" &&
      values.password !== "" &&
      values.repeatPassword !== values.password
    ) {
      error.push("Password and repeat password field doesn't match");
    }

    setErrors(error);
  }, [values, isDirty]);

  const onSubmit = async () => {
    const { data } = await updatePassword({
      variables: { id: match.params.id, password: values.password },
    });

    if (
      data.scooterUpdatePassword.errors &&
      data.scooterUpdatePassword.errors.length > 0
    ) {
      setErrors(data.scooterUpdatePassword.errors.map(i => i.message));
    } else {
      notify({
        status: "success",
        title: "Success",
        text: "Password successfully changed",
      });
      setErrors([]);
      setValues({ password: "", repeatPassword: "" });
      setIsDirty({ password: false, repeatPassword: false });
    }
  };

  return (
    <Card>
      <CardTitle title="Change scooter password" className={styles.title} />
      <CardContent>
        <div className={styles.grid}>
          <TextField
            error={false}
            label={intl.formatMessage({
              id: "OrUrql",
              defaultMessage: "Password",
              description: "Password",
            })}
            name="password"
            value={values.password}
            onChange={e => {
              setValues({ ...values, ...{ password: e.target.value } });
              setIsDirty({ ...isDirty, ...{ password: true } });
            }}
            onFocus={setIsDirty.bind(null, {
              ...isDirty,
              ...{ password: true },
            })}
          />
          <TextField
            error={false}
            label={intl.formatMessage({
              id: "wROF58",
              defaultMessage: "Repeat password",
              description: "Repeat password",
            })}
            name="repeatPassword"
            value={values.repeatPassword}
            onChange={e => {
              setValues({ ...values, ...{ repeatPassword: e.target.value } });
              setIsDirty({ ...isDirty, ...{ repeatPassword: true } });
            }}
            onFocus={setIsDirty.bind(null, {
              ...isDirty,
              ...{ repeatPassword: true },
            })}
          />
          <Button
            disabled={
              !(isDirty.password || isDirty.repeatPassword)
                ? true
                : errors.length !== 0
            }
            onClick={onSubmit}
            variant="outlined"
          >
            Change
          </Button>
          {!!errors.length && (
            <ul className={styles.errorsList}>
              {errors.map(i => (
                <li className={styles.error} key={i}>
                  {i}
                </li>
              ))}
            </ul>
          )}
        </div>
      </CardContent>
    </Card>
  );
};
