import useAppChannel from "@dashboard/components/AppLayout/AppChannelContext";
import { useEmailTemplateTranslationsQuery } from "@dashboard/graphql";
import usePaginator, { PaginatorContext } from "@dashboard/hooks/usePaginator";
import { capitalize } from "@dashboard/misc";
import TranslationsEntitiesList from "@dashboard/translations/components/TranslationsEntitiesList";
import {
  languageEntityUrl,
  TranslatableEntities,
} from "@dashboard/translations/urls";
import { mapEdgesToItems } from "@dashboard/utils/maps";
import React from "react";

import { TranslationsEntityListProps } from "./types";
import { sumCompleted } from "./utils";

const TranslationsEmailTemplatesList: React.FC<TranslationsEntityListProps> = ({
  params,
  variables,
}) => {
  const { channel } = useAppChannel();
  const { data, loading } = useEmailTemplateTranslationsQuery({
    displayLoader: true,
    variables: { ...variables, channel: channel.slug },
  });

  const paginationValues = usePaginator({
    pageInfo: data?.translations?.pageInfo,
    paginationState: variables,
    queryString: params,
  });

  return (
    <PaginatorContext.Provider value={paginationValues}>
      <TranslationsEntitiesList
        disabled={loading}
        entities={mapEdgesToItems(data?.translations)?.map(
          node =>
            node.__typename === "EmailTemplateTranslatableContent" && {
              completion: {
                current: sumCompleted([node.translation?.value]),
                max: 1,
              },
              id: node?.emailTemplate.id,
              name: capitalize(
                node?.emailTemplate.name.replaceAll("_", " ").toLowerCase(),
              ),
            },
        )}
        getRowHref={id =>
          languageEntityUrl(variables.language, TranslatableEntities.emails, id)
        }
      />
    </PaginatorContext.Provider>
  );
};

export default TranslationsEmailTemplatesList;
