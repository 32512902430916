import React from "react";
import { Route, Switch } from "react-router-dom";

import { fleetListPath, fleetPath } from "./urls";
import { FleetDetailsView, FleetListView } from "./views";

export const FleetSection: React.FC = () => (
  <Switch>
    <Route exact path={fleetListPath} component={FleetListView} />
    <Route exact path={fleetPath(":id")} component={FleetDetailsView} />
  </Switch>
);

export default FleetSection;
