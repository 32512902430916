import { TopNav } from "@dashboard/components/AppLayout";
import ButtonWithSelect from "@dashboard/components/ButtonWithSelect";
import { DateTime } from "@dashboard/components/Date";
import { DetailPageLayout } from "@dashboard/components/Layouts";
import TableRowLink from "@dashboard/components/TableRowLink";
import { useFlowMileProductsListQuery } from "@dashboard/graphql";
import useNavigator from "@dashboard/hooks/useNavigator";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import { Box } from "@saleor/macaw-ui/next";
import React, { useRef, useState } from "react";
import { FormattedMessage } from "react-intl";

import { flowmilesOfferAddPath, flowmilesOfferDetailPath } from "../urls";

const useStyles = makeStyles(
  () => ({
    tableRow: {
      cursor: "pointer",
    },
  }),
  { name: "FlowmilesPurchasesList" },
);

const itemsPerPage = 100;

export const FlowmilesOfferList = () => {
  const navigate = useNavigator();
  const [currentPage, setCurrentPage] = useState(0);
  const prevPageCursor = useRef<null | string>(null);

  const styles = useStyles();

  const { data, loading, refetch } = useFlowMileProductsListQuery({
    variables: { first: itemsPerPage },
  });

  const onPageChange = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    page: number,
  ) => {
    if (page > currentPage) {
      prevPageCursor.current = data.flowMileProducts.pageInfo.startCursor;
      refetch({
        before: undefined,
        first: itemsPerPage,
        last: undefined,
        after: data.flowMileProducts.pageInfo.endCursor,
      });
    }

    if (page < currentPage) {
      refetch({
        before: data.flowMileProducts.pageInfo.startCursor,
        first: undefined,
        last: itemsPerPage,
        after: undefined,
      });
    }

    setCurrentPage(page);
  };

  if (!data) {
    return null;
  }

  return (
    <DetailPageLayout gridTemplateColumns={1} withSavebar={false}>
      <TopNav title="FlowMiles offers">
        <ButtonWithSelect
          onClick={() => navigate(flowmilesOfferAddPath)}
          options={[]}
          data-test-id="create-flowmiles-shop"
        >
          <FormattedMessage
            id="la0fhc"
            defaultMessage="Create FlowMiles offer"
            description="button"
          />
        </ButtonWithSelect>
      </TopNav>
      <DetailPageLayout.Content>
        <Box paddingX={9} __maxWidth={"100%"} margin="auto">
          <Table size={"small"}>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Active</TableCell>
                <TableCell>Created at</TableCell>
                <TableCell>Updated at</TableCell>
                <TableCell>Release date</TableCell>
                <TableCell>Expiration date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.flowMileProducts &&
                data.flowMileProducts.edges.length > 0 &&
                data.flowMileProducts.edges.map(({ node }) => (
                  <TableRowLink
                    className={styles.tableRow}
                    href={flowmilesOfferDetailPath(node.id)}
                    key={node.id}
                    hover
                  >
                    <TableCell>{node.title}</TableCell>
                    <TableCell>{node.price}</TableCell>
                    <TableCell>{node.isActive ? "Yes" : "No"}</TableCell>
                    <TableCell>
                      <DateTime date={node.createdAt} plain />
                    </TableCell>
                    <TableCell>
                      <DateTime date={node.updatedAt} plain />
                    </TableCell>
                    <TableCell>
                      <DateTime date={node.releaseDate} plain />
                    </TableCell>
                    <TableCell>
                      <DateTime date={node.endDate} plain />
                    </TableCell>
                  </TableRowLink>
                ))}
              {data.flowMileProducts &&
                data.flowMileProducts.edges.length === 0 &&
                !loading && (
                  <TableRowLink>
                    <TableCell colSpan={7}>No FlowMiles found</TableCell>
                  </TableRowLink>
                )}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={data.flowMileProducts?.totalCount || 0}
            onPageChange={onPageChange}
            page={currentPage}
            rowsPerPage={itemsPerPage}
            rowsPerPageOptions={[itemsPerPage]}
          />
        </Box>
      </DetailPageLayout.Content>
    </DetailPageLayout>
  );
};
