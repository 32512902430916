import { ApolloError, useMutation } from "@apollo/client";
import { TopNav } from "@dashboard/components/AppLayout/TopNav";
import CardTitle from "@dashboard/components/CardTitle";
import { ListPageLayout } from "@dashboard/components/Layouts";
import useNotifier from "@dashboard/hooks/useNotifier";
import { Button, Card, CardContent, Link } from "@material-ui/core";
import { makeStyles } from "@saleor/macaw-ui";
import React, { useRef, useState } from "react";
import { MdOutlineCloudUpload } from "react-icons/md";

import { uploadLockCsv } from "../mutations";
import { UploadLockCsvResponseType } from "../types";
import { lockListPath } from "../urls";

export const useStyles = makeStyles(
  theme => ({
    error: {
      marginBottom: theme.spacing(1),
      color: "#bd2b2b",
    },
    uploadArea: {
      cursor: "pointer",
      border: "1px dashed #ccc",
      height: "72px",
      borderRadius: "4px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
    },
    button: {
      marginTop: 16,
    },
    input: {
      display: "none",
    },
    template: {
      marginTop: "25px",
    },
    label: {
      marginRight: 10,
      fontSize: 14,
    },
    icon: {
      marginRight: 16,
    },
  }),
  { name: "LockCsvUpload" },
);

export const LockCsvUploadView: React.FC = () => {
  const styles = useStyles();

  const notify = useNotifier();

  const inputRef = useRef<HTMLInputElement | null>(null);

  const [selectedFile, setSelectedFile] = useState<null | File>(null);
  const [fileError, setFileError] = useState("");

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      if (file.type !== "text/csv") {
        setFileError("Please, select correct file, only .csv available");
      } else {
        setSelectedFile(file);
        setFileError("");
      }
    }
  };

  const onUpload = async () => {
    const { data } = await uploadFile({
      variables: {
        file: selectedFile,
      },
    });

    if (data && data.lockCsvUpload && !data.lockCsvUpload.errors.length) {
      inputRef.current.value = "";
      setFileError("");
      setSelectedFile(null);
      notify({
        status: "success",
        title: "Success",
        text: "File successfully loaded",
      });
    }
  };

  const onError = (error: ApolloError) => {
    setFileError(`In file expect field: ${error.message}`);
    notify({ status: "error", title: "Error", text: "Please try again later" });
  };

  const [uploadFile] = useMutation<UploadLockCsvResponseType>(uploadLockCsv, {
    onError,
  });

  return (
    <ListPageLayout>
      <TopNav href={lockListPath} title="Lock CSV Upload" />

      <Card>
        <CardTitle title="Upload Lock CSV file" />
        <CardContent>
          <div>
            <div
              className={styles.uploadArea}
              onClick={() => inputRef.current?.click()}
            >
              {selectedFile === null ? "Select .csv file" : selectedFile.name}
              <input
                className={styles.input}
                ref={inputRef}
                type="file"
                accept=".csv"
                onChange={onFileChange}
                multiple={false}
              />
            </div>
            {fileError && <div className={styles.error}>{fileError}</div>}
          </div>

          <Button
            className={styles.button}
            onClick={onUpload}
            color="primary"
            variant="contained"
          >
            <MdOutlineCloudUpload className={styles.icon} />
            Upload file
          </Button>
          <div className={styles.template}>
            <h3 className={styles.label}>Templates:</h3>
            <Link
              href="https://storage.googleapis.com/soflow-prod/dashboard/lock_template.csv"
              download
            >
              Download (lock.csv)
            </Link>
          </div>
        </CardContent>
      </Card>
    </ListPageLayout>
  );
};
