import CardTitle from "@dashboard/components/CardTitle";
import { DateTime } from "@dashboard/components/Date";
import TableRowLink from "@dashboard/components/TableRowLink";
import { FlowPurchaseItemType } from "@dashboard/customers/types";
import {
  Card,
  makeStyles,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { ResponsiveTable } from "@saleor/macaw-ui";
import React from "react";

const useStyles = makeStyles(
  () => ({
    tableWrapper: {
      overflow: "auto",
    },
    table: {
      width: 1700,
    },
  }),
  { name: "CustomerAdditionalInfo" },
);

interface CustomerPurchasesProps {
  data: FlowPurchaseItemType[];
}

const CustomerPurchases: React.FC<CustomerPurchasesProps> = ({ data }) => {
  const styles = useStyles();

  return (
    <Card>
      <CardTitle title="FlowMiles shop purchases" />
      <div className={styles.tableWrapper}>
        <ResponsiveTable className={styles.table}>
          <TableHead>
            <TableRow>
              <TableCell>Voucher code</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>FlowMiles</TableCell>
              <TableCell>Purchase date</TableCell>
              <TableCell>Valid until</TableCell>
              <TableCell>Offer ID</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.length > 0 &&
              data.map(item => (
                <TableRowLink hover={false}>
                  <TableCell>{item.voucherCode}</TableCell>
                  <TableCell>{item.title}</TableCell>
                  <TableCell>{item.price}</TableCell>
                  <TableCell>
                    {item.createdAt ? (
                      <DateTime date={item.createdAt} plain />
                    ) : (
                      ""
                    )}
                  </TableCell>
                  <TableCell>
                    {item.validUntil ? (
                      <DateTime date={item.validUntil} plain />
                    ) : (
                      ""
                    )}
                  </TableCell>
                  <TableCell>{item.product?.id}</TableCell>
                  <TableCell>
                    {item.state === "active" ? "Not used" : "Used"}
                  </TableCell>
                </TableRowLink>
              ))}
            {data && data.length <= 0 && (
              <TableRowLink hover={false}>
                <TableCell colSpan={8}>No purchases found</TableCell>
              </TableRowLink>
            )}
          </TableBody>
        </ResponsiveTable>
      </div>
    </Card>
  );
};

CustomerPurchases.displayName = "CustomerPurchases";
export default CustomerPurchases;
