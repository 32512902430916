import ActionDialog from "@dashboard/components/ActionDialog";
import CardTitle from "@dashboard/components/CardTitle";
import Grid from "@dashboard/components/Grid";
import Link from "@dashboard/components/Link";
import { SooterOwnerType } from "@dashboard/fleet/types";
import {
  useScooterAssignOwnerMutation,
  useScooterRemoveOwnerMutation,
} from "@dashboard/graphql";
import useNotifier from "@dashboard/hooks/useNotifier";
import {
  Button,
  Card,
  CardContent,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { ConfirmButtonTransitionState } from "@saleor/macaw-ui";
import { TrashBinIcon } from "@saleor/macaw-ui/next";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import useRouter from "use-react-router";

const useStyles = makeStyles(
  () => ({
    list: {
      marginTop: -8,
      fontSize: 13,
    },
    line: {
      display: "flex",
      alignItems: "center",
      marginTop: 3,
    },
    value: {
      marginLeft: 4,
      marginRight: 4,
    },
    deleteIcon: {
      color: "red",
      cursor: "pointer",
      display: "block",
    },
    form: {
      marginTop: 15,
    },
    label: {
      paddingRight: 5,
    },
    error: {
      color: "red",
    },
    errorsList: {},
    title: {
      color: "lightseagreen",
    },
  }),
  { name: "Owner" },
);

const emptyValue = "No data";

interface ScooterOwnerProps {
  owner: SooterOwnerType | null;
  onOwnerDelete: () => void;
}

export const Owner: React.FC<ScooterOwnerProps> = ({
  owner,
  onOwnerDelete,
}) => {
  const notify = useNotifier();
  const { match } = useRouter<{ id: string }>();
  const [email, setEmail] = useState("");

  const [errors, setErrors] = useState<string[]>([]);
  const [isDirty, setIsDirty] = useState(false);
  const [showRemoveModalConfirmation, changeRemoveModalConfirmationState] =
    useState(false);
  const [removeBtnState, changeRemoveBtnState] =
    useState<ConfirmButtonTransitionState>("default");

  const styles = useStyles();
  const intl = useIntl();

  const [assignOwner] = useScooterAssignOwnerMutation();
  const [removeOwner] = useScooterRemoveOwnerMutation();

  useEffect(() => {
    const error = [];

    if (isDirty) {
      if (!email) {
        error.push("Email is required");
      } else {
        if (
          !/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
            email,
          )
        ) {
          error.push("Email address is incorrect");
        }
      }
    }

    setErrors(error);
  }, [email, isDirty]);

  const onSubmit = async () => {
    const { data } = await assignOwner({
      variables: { id: match.params.id, email },
    });

    if (
      data.scooterAssignOwner.errors &&
      data.scooterAssignOwner.errors.length > 0
    ) {
      setErrors(data.scooterAssignOwner.errors.map(i => i.message));
    } else {
      notify({
        status: "success",
        title: "Success",
        text: "Scooter owner successfully changed",
      });
      setErrors([]);
      setEmail("");
      setIsDirty(false);
    }
  };

  const onRemoveOwner = async () => {
    changeRemoveBtnState("loading");

    const { data } = await removeOwner({ variables: { id: match.params.id } });

    if (
      data.scooterRemoveOwner.errors &&
      data.scooterRemoveOwner.errors.length === 0
    ) {
      notify({
        status: "success",
        title: "Success",
        text: "Scooter owner successfully removed",
      });
      onOwnerDelete();
    }
    changeRemoveBtnState("default");
    changeRemoveModalConfirmationState(false);
  };

  return (
    <Card>
      <CardTitle title="Reset owner" className={styles.title} />
      <CardContent>
        {owner && (
          <ul className={styles.list}>
            <li className={styles.line}>
              <span className={styles.label}>Current email:</span>
              <b>
                {owner?.user.email ? (
                  <Link href={`/customers/${owner.user.id}`} target="_blank">
                    {owner.user.email}
                  </Link>
                ) : (
                  emptyValue
                )}
              </b>
              <span
                onClick={changeRemoveModalConfirmationState.bind(null, true)}
              >
                <TrashBinIcon className={styles.deleteIcon} size="small" />
              </span>
            </li>
          </ul>
        )}
        <ul className={styles.errorsList}>
          {errors.map(i => (
            <li className={styles.error} key={i}>
              {i}
            </li>
          ))}
        </ul>
        <Grid className={styles.form} variant="default">
          <TextField
            error={false}
            label={intl.formatMessage({
              id: "RxwPm6",
              defaultMessage: "New owner email",
              description: "New owner email",
            })}
            name="modalName"
            value={email}
            onChange={e => {
              setEmail(e.target.value);
              setIsDirty(true);
            }}
            onFocus={setIsDirty.bind(null, true)}
          />
          <Button
            disabled={!isDirty ? true : errors.length !== 0}
            onClick={onSubmit}
            variant="outlined"
          >
            Change
          </Button>
        </Grid>
      </CardContent>
      <ActionDialog
        open={showRemoveModalConfirmation}
        confirmButtonState={removeBtnState}
        title="Confirm owner remove"
        onConfirm={onRemoveOwner}
        onClose={changeRemoveModalConfirmationState.bind(null, false)}
      >
        Are you sure, you want to remove owner email:{" "}
        <b>{owner?.user?.email}</b>
      </ActionDialog>
    </Card>
  );
};
