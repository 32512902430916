import React from "react";
import { Route, Switch } from "react-router-dom";

import { lockCsvUploadURL, lockListPath, lockPath } from "./urls";
import { LockCsvUploadView, LockDetailsView, LockListView } from "./views";

export const LockSection: React.FC = () => (
  <Switch>
    <Route exact path={lockListPath} component={LockListView} />
    <Route exact path={lockCsvUploadURL} component={LockCsvUploadView} />
    <Route exact path={lockPath(":id")} component={LockDetailsView} />
  </Switch>
);

export default LockSection;
