import { TopNav } from "@dashboard/components/AppLayout";
import { DetailPageLayout } from "@dashboard/components/Layouts";
import { useFlowMileProductCreateMutation } from "@dashboard/graphql";
import useNavigator from "@dashboard/hooks/useNavigator";
import useNotifier from "@dashboard/hooks/useNotifier";
import { ConfirmButtonTransitionState } from "@saleor/macaw-ui";
import React, { useState } from "react";
import { useIntl } from "react-intl";

import { FlowmilesOfferForm, FormData, LanguageSwitcher } from "../components";
import { mapToFlowmilesOfferServerData } from "../mappers";
import { flowmilesOfferDetailPath, flowmilesOfferPath } from "../urls";

const defaultValues: FormData = {
  company: "",
  descriptionDe: "",
  descriptionEn: "",
  descriptionEs: "",
  descriptionFr: "",
  descriptionIt: "",
  expirationDate: "",
  expirationTime: "",
  gradientEnd: "",
  gradientStart: "",
  image: "",
  isActive: false,
  price: 0,
  releaseDate: "",
  releaseTime: "",
  shopLinkDe: "",
  shopLinkEn: "",
  shopLinkEs: "",
  shopLinkFr: "",
  shopLinkIt: "",
  titleDe: "",
  titleEn: "",
  titleEs: "",
  titleFr: "",
  titleIt: "",
  periodEndDate: "",
  periodEndTime: "",
  periodStartDate: "",
  periodStartTime: "",
  voucher: "",
};

export const FlowmilesOfferCreate = () => {
  const notify = useNotifier();
  const navigate = useNavigator();
  const intl = useIntl();

  const [language, setLanguage] = useState("de");
  const [errors, setErrors] = useState([]);
  const [state, setState] = useState<ConfirmButtonTransitionState>("default");

  const [createItem] = useFlowMileProductCreateMutation();

  const onSubmit = async (formData: FormData) => {
    setState("loading");
    const input = mapToFlowmilesOfferServerData(formData);
    const { data } = await createItem({ variables: { input } });
    if (
      data.flowMileProductCreate.errors &&
      data.flowMileProductCreate.errors.length > 0
    ) {
      setErrors(data.flowMileProductCreate.errors.map(i => i.message));
    } else {
      notify({
        status: "success",
        title: "Success",
        text: "Flowmile product successfully created",
      });
      setErrors([]);
      navigate(
        flowmilesOfferDetailPath(data.flowMileProductCreate.flowProduct.id),
      );
    }
    setState("default");
  };

  return (
    <DetailPageLayout gridTemplateColumns={1} withSavebar={false}>
      <TopNav
        href={flowmilesOfferPath}
        title={intl.formatMessage({
          id: "ve+hSc",
          defaultMessage: "Create FlowMiles offer",
          description: "page header",
        })}
      >
        <LanguageSwitcher defaultValue={language} onChange={setLanguage} />
      </TopNav>
      <DetailPageLayout.Content>
        <FlowmilesOfferForm
          serverErrors={errors}
          cancelUrl={flowmilesOfferPath}
          confirmBtnText="Create"
          data={defaultValues}
          language={language}
          onSubmit={onSubmit}
          progressState={state}
        />
      </DetailPageLayout.Content>
    </DetailPageLayout>
  );
};
