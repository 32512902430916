import { TopNav } from "@dashboard/components/AppLayout/TopNav";
import DeleteFilterTabDialog from "@dashboard/components/DeleteFilterTabDialog";
import { DetailPageLayout } from "@dashboard/components/Layouts";
import SaveFilterTabDialog, {
  SaveFilterTabDialogFormData,
} from "@dashboard/components/SaveFilterTabDialog";
import SearchBar from "@dashboard/components/SearchBar";
import TableRowLink from "@dashboard/components/TableRowLink";
import { useLocksQuery } from "@dashboard/graphql";
import useNavigator from "@dashboard/hooks/useNavigator";
import {
  Button,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import { Box } from "@saleor/macaw-ui/next";
import React, { useEffect, useState } from "react";
import { PiWrenchFill } from "react-icons/pi";
import { FormattedMessage } from "react-intl";

import { LockSavedFilterItemType } from "../types";
import { lockCsvUploadURL, lockPath } from "../urls";

const useStyles = makeStyles(
  theme => ({
    downloadWrapper: {
      display: "flex",
      alignItems: "center",
      padding: "6px 0",
      borderBottom: "1px solid rgb(234, 234, 234)",
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    fileUpload: {
      display: "none",
    },
    downloadText: {
      opacity: "0.6",
    },
    downloadBtn: {
      marginRight: 10,
    },
    tableRow: {
      cursor: "pointer",
    },
  }),
  { name: "LockListView" },
);

const LOCAL_STORAGE_KEY = "lockFilters";
const itemsPerPage = 100;

export const LockListView = () => {
  const styles = useStyles();
  const navigate = useNavigator();

  const [search, setSearch] = useState("");
  const [currentTab, setCurrentTab] = useState(0);
  const [tabs, setTabs] = useState([]);
  const [saveFilterModalState, setSaveFilterModalState] = useState(false);
  const [deleteFilterModalState, setDeleteFilterModalState] = useState(false);
  const [savedFilters, setSavedFilters] = useState<LockSavedFilterItemType[]>(
    [],
  );

  const [currentPage, setCurrentPage] = useState(0);

  const queryVariables = React.useMemo(() => {
    const result: any = {};

    if (search) {
      result.search = search;
    }

    return result;
  }, [search]);

  useEffect(() => {
    const result = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (result) {
      const data = JSON.parse(result) as LockSavedFilterItemType[];
      setTabs(data.map(i => i.name));
      setSavedFilters(data);
    }
  }, []);

  const { data, loading, refetch } = useLocksQuery({
    displayLoader: true,
    variables: { filter: queryVariables, first: itemsPerPage },
  });

  const onPageChange = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    page: number,
  ) => {
    if (page > currentPage) {
      refetch({
        before: undefined,
        first: itemsPerPage,
        last: undefined,
        after: data.locks.pageInfo.endCursor,
      });
    }

    if (page < currentPage) {
      refetch({
        before: data.locks.pageInfo.startCursor,
        first: undefined,
        last: itemsPerPage,
        after: undefined,
      });
    }

    setCurrentPage(page);
  };

  const handleTabSave = ({ name }: SaveFilterTabDialogFormData) => {
    setTabs([...tabs, ...[name]]);

    const newItem: LockSavedFilterItemType = {
      data: {},
      name,
    };

    if (search) {
      newItem.data.query = search;
    }

    const filtersToUpdate = [...savedFilters, ...[newItem]];

    setSavedFilters(filtersToUpdate);
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(filtersToUpdate));
    setSaveFilterModalState(false);
  };

  const toNewTab = () => {
    setCurrentTab(tabs.length + 1);
  };

  const handleTabDelete = () => {
    setSearch("");
    const name = tabs[currentTab - 1];
    setCurrentTab(0);
    setTabs(tabs.filter(i => i !== name));
    const newFilters = savedFilters.filter(i => i.name !== name);
    setSavedFilters(newFilters);
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newFilters));
    setDeleteFilterModalState(false);
  };

  const onAll = () => {
    setSearch("");
    setCurrentTab(0);
  };

  const onSearchChange = (value: string) => {
    setSearch(value);
    toNewTab();
  };

  const onTabChange = (tab: number) => {
    const { data } = savedFilters[tab - 1];

    if (data) {
      setSearch(data.query || "");
    }

    setCurrentTab(tab);
  };

  return (
    <DetailPageLayout gridTemplateColumns={1} withSavebar={false}>
      <TopNav title="Locks">
        <Button
          onClick={() => navigate(lockCsvUploadURL)}
          variant="contained"
          color="primary"
          data-test-id="manage-locks"
        >
          <PiWrenchFill style={{ marginRight: 8 }} />
          <FormattedMessage
            id="LcLzRt"
            defaultMessage="CSV Upload"
            description="button"
          />
        </Button>
      </TopNav>
      <DetailPageLayout.Content>
        <SearchBar
          allTabLabel="All locks"
          currentTab={currentTab}
          initialSearch={search}
          onAll={onAll}
          onSearchChange={onSearchChange}
          onTabChange={onTabChange}
          onTabDelete={setDeleteFilterModalState.bind(null, true)}
          onTabSave={setSaveFilterModalState.bind(null, true)}
          searchPlaceholder="Search locks"
          tabs={tabs}
        />
        <Box paddingX={9} __maxWidth={"100%"} margin="auto">
          <Table size={"small"}>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Serial Number</TableCell>
                <TableCell>MAC address</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Owner</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.locks &&
                data.locks.edges.length > 0 &&
                data.locks.edges.map(({ node }, i) => (
                  <TableRowLink
                    className={styles.tableRow}
                    key={node.id}
                    href={node && lockPath(node.id)}
                  >
                    <TableCell
                      style={{
                        maxWidth: "10px",
                        minWidth: "10px",
                        width: "10px",
                      }}
                    >
                      {i + 1 + currentPage * 100}
                    </TableCell>
                    <TableCell>{node.serialNumber}</TableCell>
                    <TableCell>{node.mac}</TableCell>
                    <TableCell>{node.status}</TableCell>
                    <TableCell>{node.owner?.user.email || "-"}</TableCell>
                  </TableRowLink>
                ))}
              {data &&
                data.locks &&
                data.locks.edges.length === 0 &&
                !loading && (
                  <TableRowLink>
                    <TableCell colSpan={6}>No locks found</TableCell>
                  </TableRowLink>
                )}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={data?.locks?.totalCount || 0}
            onPageChange={onPageChange}
            page={currentPage}
            rowsPerPage={itemsPerPage}
            rowsPerPageOptions={[itemsPerPage]}
          />
        </Box>
      </DetailPageLayout.Content>
      <SaveFilterTabDialog
        open={saveFilterModalState}
        confirmButtonState="default"
        onClose={setSaveFilterModalState.bind(null, false)}
        onSubmit={handleTabSave}
      />
      <DeleteFilterTabDialog
        open={deleteFilterModalState}
        confirmButtonState="default"
        onClose={setDeleteFilterModalState.bind(null, false)}
        onSubmit={handleTabDelete}
        tabName={tabs[currentTab - 1]}
      />
    </DetailPageLayout>
  );
};
