import CardSpacer from "@dashboard/components/CardSpacer";
import RequirePermissions from "@dashboard/components/RequirePermissions";
import ResponsiveTable from "@dashboard/components/ResponsiveTable";
import {
  FulfillmentStatus,
  OrderDetailsFragment,
  PermissionEnum,
} from "@dashboard/graphql";
import { useFlags } from "@dashboard/hooks/useFlags";
import TrashIcon from "@dashboard/icons/Trash";
import { orderHasTransactions, OrderSharedType } from "@dashboard/orders/types";
import { mergeRepeatedOrderLines } from "@dashboard/orders/utils/data";
import { Card, CardContent, TableBody } from "@material-ui/core";
import { IconButton } from "@saleor/macaw-ui";
import React from "react";

import { renderCollection } from "../../../misc";
import OrderCardTitle from "../OrderCardTitle";
import TableHeader from "../OrderProductsCardElements/OrderProductsCardHeader";
import TableLine from "../OrderProductsCardElements/OrderProductsTableRow";
import ActionButtons from "./ActionButtons";
import ExtraInfoLines from "./ExtraInfoLines";
import useStyles from "./styles";

interface OrderFulfilledProductsCardProps {
  fulfillment: OrderDetailsFragment["fulfillments"][0];
  fulfillmentAllowUnpaid: boolean;
  order?: OrderSharedType;
  onOrderFulfillmentApprove: () => void;
  onOrderFulfillmentCancel: () => void;
  onTrackingCodeAdd: () => void;
}

const statusesToMergeLines = [
  FulfillmentStatus.REFUNDED,
  FulfillmentStatus.REFUNDED_AND_RETURNED,
  FulfillmentStatus.RETURNED,
  FulfillmentStatus.REPLACED,
];
const cancelableStatuses = [
  FulfillmentStatus.FULFILLED,
  FulfillmentStatus.WAITING_FOR_APPROVAL,
];

const OrderFulfilledProductsCard: React.FC<
  OrderFulfilledProductsCardProps
> = props => {
  const {
    fulfillment,
    fulfillmentAllowUnpaid,
    order,
    onOrderFulfillmentApprove,
    onOrderFulfillmentCancel,
    onTrackingCodeAdd,
  } = props;
  const classes = useStyles(props);
  const { orderTransactions: transactionsFeatureFlag } = useFlags([
    "orderTransactions",
  ]);

  if (!fulfillment) {
    return null;
  }

  const getLines = () => {
    if (statusesToMergeLines.includes(fulfillment?.status)) {
      return mergeRepeatedOrderLines(fulfillment.lines);
    }

    return fulfillment?.lines || [];
  };

  return (
    <>
      <Card>
        <OrderCardTitle
          withStatus
          lines={fulfillment?.lines}
          fulfillmentOrder={fulfillment?.fulfillmentOrder}
          status={fulfillment?.status}
          warehouseName={fulfillment?.warehouse?.name}
          orderNumber={order?.number}
          toolbar={
            cancelableStatuses.includes(fulfillment?.status) && (
              <RequirePermissions
                requiredPermissions={[PermissionEnum.MANAGE_ORDERS]}
              >
                <IconButton
                  variant="secondary"
                  className={classes.deleteIcon}
                  onClick={onOrderFulfillmentCancel}
                  data-test-id="cancel-fulfillment-button"
                >
                  <TrashIcon />
                </IconButton>
              </RequirePermissions>
            )
          }
        />
        <CardContent>
          <ResponsiveTable className={classes.table}>
            <TableHeader />
            <TableBody>
              {renderCollection(getLines(), line => (
                <TableLine key={line.id} line={line} />
              ))}
            </TableBody>
            <ExtraInfoLines fulfillment={fulfillment} />
          </ResponsiveTable>
          <RequirePermissions
            requiredPermissions={[PermissionEnum.MANAGE_ORDERS]}
          >
            <ActionButtons
              orderId={order?.id}
              status={fulfillment?.status}
              trackingNumber={fulfillment?.trackingNumber}
              orderIsPaid={order?.isPaid}
              fulfillmentAllowUnpaid={fulfillmentAllowUnpaid}
              onTrackingCodeAdd={onTrackingCodeAdd}
              onApprove={onOrderFulfillmentApprove}
              hasTransactions={orderHasTransactions(
                order,
                transactionsFeatureFlag.enabled,
              )}
            />
          </RequirePermissions>
        </CardContent>
      </Card>
      <CardSpacer />
    </>
  );
};

export default OrderFulfilledProductsCard;
