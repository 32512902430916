import CardTitle from "@dashboard/components/CardTitle";
import TableRowLink from "@dashboard/components/TableRowLink";
import { useFlowMileVoucherCodesQuery } from "@dashboard/graphql";
import {
  Card,
  CardContent,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import { ResponsiveTable } from "@saleor/macaw-ui";
import React, { useState } from "react";

const itemsPerPage = 7;

interface VoucherCodesProps {
  isUsed: boolean;
  title: string;
  id: string;
}

export const VoucherCodes: React.FC<VoucherCodesProps> = ({
  isUsed,
  title,
  id,
}) => {
  const [currentPage, setCurrentPage] = useState(0);

  const { data, refetch } = useFlowMileVoucherCodesQuery({
    displayLoader: true,
    variables: { first: itemsPerPage, id, isUsed },
  });

  const onPageChange = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    page: number,
  ) => {
    if (page > currentPage) {
      refetch({
        before: undefined,
        first: itemsPerPage,
        last: undefined,
        after: data.flowMileVoucherCodes.pageInfo.endCursor,
      });
    }

    if (page < currentPage) {
      refetch({
        before: data.flowMileVoucherCodes.pageInfo.startCursor,
        first: undefined,
        last: itemsPerPage,
        after: undefined,
      });
    }

    setCurrentPage(page);
  };

  return (
    <Card>
      <CardTitle title={title} />
      <CardContent>
        <ResponsiveTable>
          <TableHead>
            <TableRow>
              <TableCell>Code</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.flowMileVoucherCodes?.edges &&
              data?.flowMileVoucherCodes.edges.length > 0 && (
                <>
                  {data.flowMileVoucherCodes.edges.map(({ node }) => (
                    <TableRowLink hover={false}>
                      <TableCell colSpan={1}>{node.code}</TableCell>
                    </TableRowLink>
                  ))}
                </>
              )}
            {(!data?.flowMileVoucherCodes?.edges ||
              data.flowMileVoucherCodes.edges.length === 0) && (
              <TableRowLink hover={false}>
                <TableCell colSpan={1}>No voucher codes found</TableCell>
              </TableRowLink>
            )}
          </TableBody>
        </ResponsiveTable>
        <TablePagination
          component="div"
          count={data?.flowMileVoucherCodes?.totalCount || 0}
          onPageChange={onPageChange}
          page={currentPage}
          rowsPerPage={itemsPerPage}
          rowsPerPageOptions={[itemsPerPage]}
        />
      </CardContent>
    </Card>
  );
};

VoucherCodes.displayName = "VoucherCodes";
