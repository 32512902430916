import { IFilter } from "@dashboard/components/Filter";
import { hasPermissions } from "@dashboard/components/RequirePermissions";
import { PermissionEnum, UserFragment } from "@dashboard/graphql";
import { FilterOpts, MinMax } from "@dashboard/types";
import {
  createDateField,
  createNumberField,
} from "@dashboard/utils/filters/fields";
import { defineMessages, IntlShape } from "react-intl";

export enum CustomerFilterKeys {
  joined = "joined",
  numberOfOrders = "orders",
  lastLogin = "lastLogin",
  availableFlowMiles = "availableFlowMiles",
  birthdate = "birthdate",
}

export interface CustomerListFilterOpts {
  joined: FilterOpts<MinMax>;
  numberOfOrders: FilterOpts<MinMax>;
  lastLogin: FilterOpts<MinMax>;
  availableFlowMiles: FilterOpts<MinMax>;
  birthdate: FilterOpts<MinMax>;
}

const messages = defineMessages({
  joinDate: {
    id: "icz/jb",
    defaultMessage: "Join Date",
    description: "customer",
  },
  numberOfOrders: {
    id: "fhksPD",
    defaultMessage: "Number of Orders",
  },
  lastLogin: {
    id: "FNAZoh",
    defaultMessage: "Last login",
  },
  birthdate: {
    id: "WMQYUt",
    defaultMessage: "Birthdate",
  },
  availableFlowMiles: {
    id: "V2wumE",
    defaultMessage: "Available FlowMiles",
  },
});

export function createFilterStructure(
  intl: IntlShape,
  opts: CustomerListFilterOpts,
  userPermissions: UserFragment["userPermissions"],
): IFilter<CustomerFilterKeys> {
  return [
    {
      ...createDateField(
        CustomerFilterKeys.birthdate,
        intl.formatMessage(messages.birthdate),
        opts.birthdate.value,
      ),
      active: opts.birthdate.active,
    },
    {
      ...createDateField(
        CustomerFilterKeys.joined,
        intl.formatMessage(messages.joinDate),
        opts.joined.value,
      ),
      active: opts.joined.active,
    },
    {
      ...createDateField(
        CustomerFilterKeys.lastLogin,
        intl.formatMessage(messages.lastLogin),
        opts.lastLogin.value,
      ),
      active: opts.lastLogin.active,
    },
    {
      ...createNumberField(
        CustomerFilterKeys.numberOfOrders,
        intl.formatMessage(messages.numberOfOrders),
        opts.numberOfOrders.value,
      ),
      active: opts.numberOfOrders.active,
      permissions: [PermissionEnum.MANAGE_ORDERS],
    },
    {
      ...createNumberField(
        CustomerFilterKeys.availableFlowMiles,
        intl.formatMessage(messages.availableFlowMiles),
        opts.availableFlowMiles.value,
      ),
      active: opts.availableFlowMiles.active,
    },
  ].filter(filter => hasPermissions(userPermissions, filter.permissions ?? []));
}
