import CardTitle from "@dashboard/components/CardTitle";
import Link from "@dashboard/components/Link";
import TableRowLink from "@dashboard/components/TableRowLink";
import { Lock } from "@dashboard/locks/types";
import {
  Card,
  makeStyles,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { ResponsiveTable } from "@saleor/macaw-ui";
import React from "react";

const useStyles = makeStyles(
  () => ({
    tableWrapper: {
      overflow: "auto",
    },
  }),
  { name: "CustomerOwnedLocks" },
);

interface CustomerLocksProps {
  title: string;
  data: Lock[];
}

const CustomerLocks: React.FC<CustomerLocksProps> = ({ title, data }) => {
  const styles = useStyles();

  return (
    <Card>
      <CardTitle title={title} />
      <div className={styles.tableWrapper}>
        <ResponsiveTable>
          <TableHead>
            <TableRow>
              <TableCell>Serial number</TableCell>
              <TableCell>MAC address</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.length > 0 &&
              data.map(item => (
                <TableRowLink hover={false}>
                  <TableCell>
                    <Link href={`/locks/${item.id}`}>{item.serialNumber}</Link>
                  </TableCell>
                  <TableCell>{item.mac}</TableCell>
                </TableRowLink>
              ))}
            {data && data.length <= 0 && (
              <TableRowLink hover={false}>
                <TableCell colSpan={8}>No locks found.</TableCell>
              </TableRowLink>
            )}
          </TableBody>
        </ResponsiveTable>
      </div>
    </Card>
  );
};

CustomerLocks.displayName = "CustomerLocks";
export default CustomerLocks;
