import {
  useTriggerErpSyncMutation,
  useTriggerPimSyncMutation,
} from "@dashboard/graphql";
import useNotifier from "@dashboard/hooks/useNotifier";
import {
  ConfirmButton,
  ConfirmButtonTransitionState,
  makeStyles,
} from "@saleor/macaw-ui";
import { Box, List } from "@saleor/macaw-ui/next";
import React, { useState } from "react";

import { MenuItem } from "./Item";
import { useMenuStructure } from "./useMenuStructure";

const useStyles = makeStyles(
  () => ({
    button: {
      width: "100%",
      marginTop: 30,
    },
  }),
  { name: "MenuComponent" },
);

export const Menu = () => {
  const notify = useNotifier();
  const classes = useStyles();
  const [pimState, setPimState] =
    useState<ConfirmButtonTransitionState>("default");
  const [erpState, setErpState] =
    useState<ConfirmButtonTransitionState>("default");
  const menuStructure = useMenuStructure();

  const [syncPim] = useTriggerPimSyncMutation();
  const [syncErp] = useTriggerErpSyncMutation();

  const onSyncPim = async () => {
    setPimState("loading");
    const { data } = await syncPim();

    if (data?.triggerPimSync?.errors && data.triggerPimSync.errors.length) {
      notify({
        status: "error",
        title: "Some error happened, please try again later",
      });
    } else {
      notify({ status: "success", title: "PIM data successfully updated" });
    }
    setPimState("default");
  };

  const onSyncERP = async () => {
    setErpState("loading");
    const { data } = await syncErp();

    if (data?.triggerErpSync?.errors && data.triggerErpSync.errors.length) {
      notify({
        status: "error",
        title: "Some error happened, please try again later",
      });
    } else {
      notify({ status: "success", title: "ERP data successfully updated" });
    }
    setErpState("default");
  };

  return (
    <Box padding={6} overflowY="auto" className="hide-scrollbar">
      <List as="ol" display="grid" gap={3} data-test-id="menu-list">
        {menuStructure.map(menuItem => (
          <MenuItem menuItem={menuItem} key={menuItem.id} />
        ))}
      </List>
      <ConfirmButton
        className={classes.button}
        variant="secondary"
        transitionState={pimState}
        labels={{ confirm: "", error: "" }}
        onClick={onSyncPim}
      >
        Sync PIM data
      </ConfirmButton>
      <ConfirmButton
        className={classes.button}
        variant="secondary"
        transitionState={erpState}
        labels={{ confirm: "", error: "" }}
        onClick={onSyncERP}
      >
        Sync ERP data
      </ConfirmButton>
    </Box>
  );
};
